import get from "lodash/get";
import React from "react";
import styles from "./index.module.css";

// Illustration can be in svg/vector format or png/jpg/bitmap. This component will
// work out which, and render the right markup. Defaults to an empty component if can't
// detect illustration type
const Illustration = ({ illustrationImage, title, className }) => {
  const BITMAP = `BITMAP`;
  const VECTOR = `VECTOR`;
  let illustration = false;
  if (get(illustrationImage, `childImageSharp.fluid`)) illustration = BITMAP;
  if (get(illustrationImage, `extension`) === `svg`) illustration = VECTOR;

  return (
    <React.Fragment>
      {illustration === VECTOR && (
        <div
          className={`${className || styles.illustration}`}
          style={{ backgroundImage: `url(${illustrationImage.publicURL})` }}
          title={title}
        />
      )}
      {illustration === BITMAP && (
        <div
          className={`${className || styles.illustration}`}
          style={{
            backgroundImage: `url(${illustrationImage.childImageSharp.fluid.src})`
          }}
          title={title}
        />
      )}
    </React.Fragment>
  );
};

export default Illustration;
