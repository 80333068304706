module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-50},
    },{
      plugin: require('../plugins/wrap-with-context/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/tracking-soet/gatsby-browser.js'),
      options: {"plugins":[],"google":{"trackingId":"UA-36046017-2","anonymize":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The State of European Tech 2021","short_name":"State of European Tech 2021","start_url":"/","background_color":"#002b38","theme_color":"#99dbcf","display":"standalone","icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fb2dcfae2aee409e841985ef4bce616e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/ubuntu/frontend_build/src/components/Root/index.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
