// eslint-disable-next-line import/no-unresolved
import { connect } from "react-redux";
import BlockChart from "../BlockChart";

// eslint-disable-next-line import/no-unresolved
import { requestChartDownload } from "../../state/download/chart";
// import { getChart } from "../../state/chart";

const withRedux = connect(
  () => ({
    // preSelectedChart: getChart(state)
  }),
  dispatch => ({
    onDownloadClick(
      chartId,
      groupId,
      chartRef,
      chartTitleNumber,
      disabledDataDownload
    ) {
      dispatch(
        requestChartDownload({
          chartId,
          groupId,
          chartRef,
          chartTitleNumber,
          disabledDataDownload
        })
      );
    }
  })
);

export default withRedux(BlockChart);
