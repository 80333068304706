import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import Grid, { Half } from "../Grid";
import ScrollArrow from "../ScrollArrow";
import Illustration from "../Illustration";
import HeaderInsights from "../HeaderInsights";
import ExecutiveSummary from "../ExecutiveSummary";

const DefaultChapter = ({
  entityId,
  titleNumber,
  title,
  illustrationImage,
  footerIllustrationImage,
  subTitle,
  summaryTitle,
  summaryText,
  path,
  articles
  // location
}) => {
  const ResponsiveIllustration = ({ className }) => {
    return (
      <Illustration
        className={className}
        illustrationImage={illustrationImage}
        title={title}
      />
    );
  };

  return (
    <>
      <ResponsiveIllustration className={styles.illustrationTop} />
      <Grid className={styles.topBanner}>
        <Half
          className={styles.root}
          id={`chapter-${entityId}`}
          data-observe-position
          data-pathname={path}
        >
          <div className={styles.innerRoot}>
            <div id={`chapter-wrapper`} className={styles.titleWrapper}>
              <div className={styles.chapterNumber}>{titleNumber}</div>
              <h1 data-chapter={titleNumber} className={styles.title}>
                <span className={styles.titleText}>{title}</span>
              </h1>
            </div>
            <div className={styles.chapterContent}>
              <h2 hidden className={styles.subTitle}>
                {subTitle}
              </h2>
            </div>
          </div>
        </Half>
        <ResponsiveIllustration className={styles.illustration} />
      </Grid>
      <HeaderInsights
        className={styles.chapterInsights}
        title={summaryTitle}
        text={summaryText}
        insights={articles.map(a => ({
          number: a.titleNumber,
          path: a.path,
          title: a.title,
          text: a.keyFinding,
          titleNumber: a.titleNumber,
          source: a.keyFindingSource
        }))}
      >
        {articles.length > 0 && (
          <ScrollArrow
            className={styles.mainScrollArrow}
            to={`#insights`}
            title={articles[0].title}
          />
        )}
        <div className={styles.footerIllustrationContainer}>
          <Illustration
            className={styles.footerIllustration}
            illustrationImage={footerIllustrationImage}
          />
        </div>
      </HeaderInsights>
    </>
  );
};

DefaultChapter.propTypes = {
  entityId: PropTypes.number,
  illustrationImage: PropTypes.shape({
    fluid: PropTypes.shape({}),
    extension: PropTypes.string,
    publicURL: PropTypes.string
  }),
  titleNumber: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  seoTitle: PropTypes.string,
  path: PropTypes.string,
  subTitle: PropTypes.string,
  text: PropTypes.string,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      path: PropTypes.string,
      title: PropTypes.string,
      keyFinding: PropTypes.string,
      keyFindingSource: PropTypes.string,
      titleNumber: PropTypes.string
    })
  )
};

const Chapter = props => {
  if (props.titleNumber === `00`) {
    return <ExecutiveSummary />;
  } else {
    return <DefaultChapter {...props} />;
  }
};

export default Chapter;
