import React from "react";
import styles from "./index.module.css";
import MainContents from "../ReportNav/NavContent/MainContents";
import PropTypes from "prop-types";
import Illustration from "../Illustration";
import { Link } from "gatsby";
import LinkArrow from "./arrow.svg";
import { Full } from "../Grid";

const ArticleFooter = ({ next }) => {
  return (
    <div className={styles.root}>
      <Full>
        <div className={styles.upNextWrapper}>
          <Illustration
            className={styles.upNextIllu}
            illustrationImage={next.illustrationImage}
            title={next.title}
          />
          <Link to={next.path} className={styles.upNextLink}>
            <span className={styles.upNextHeading}>Up Next:</span>
            <span className={styles.upNextNumber}>{next.titleNumber}</span>
            <span className={styles.upNextTitle}>{next.title}</span>
            <span className={styles.upNextArrow}>
              <LinkArrow className={styles.upNextArrowIcon} />
            </span>
          </Link>
        </div>
        <div className={styles.contentsWrapper}>
          <MainContents scrollToView={false} />
        </div>
      </Full>
    </div>
  );
};

ArticleFooter.propTypes = {
  next: PropTypes.shape({
    path: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    titleNumber: PropTypes.titleNumber,
    illustrationImage: PropTypes.any
  })
};

export default ArticleFooter;
