import React from "react";
import NavContext from "../../NavContext";

export const NAV_TABS = {
  CONTENTS: `contents`,
  PREVIOUS_REPORTS: `previous reports`,
  PARTNERS: `partners`
};

const NavTabContext = React.createContext({
  selectedTab: NAV_TABS.CONTENTS,
  setSelectedTab: () => {}
});

export const NavTabsContextProvider = ({ children }) => {
  const [selectedTab, setSelectedTab] = React.useState(NAV_TABS.CONTENTS);
  const { toggleReportNav, reportNavOpen } = React.useContext(NavContext);

  React.useEffect(() => {
    if (!reportNavOpen) {
      setSelectedTab(NAV_TABS.CONTENTS);
    }
  }, [reportNavOpen]);

  return (
    <NavTabContext.Provider value={{ selectedTab, setSelectedTab }}>
      {children}
    </NavTabContext.Provider>
  );
};

export default NavTabContext;
