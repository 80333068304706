import React, { useEffect, useState, useRef } from "react"
import { debounce } from "lodash-es"
import PropTypes from "prop-types"
import cn from "classnames"
import FlyWheel from "../FlyWheel/index.js"
import * as styles from "./index.module.css"

const Plan = ({ useVideo }) => {
  const asset = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const hasLoaded = () => {
    setLoaded(true)
  }

  useEffect(() => {
    const video = asset.current

    setLoaded(true)

    const scrollStart = debounce(
      () => {
        video?.pause()
      },
      500,
      { leading: true, trailing: false }
    )

    const scrollEnd = debounce(() => {
      video?.play()
    }, 500)

    if (video && useVideo) {
      video.addEventListener("canplay", hasLoaded)
      window.addEventListener("scroll", scrollStart, { passive: true })
      window.addEventListener("scroll", scrollEnd, { passive: true })
    } else {
      hasLoaded()
    }

    if (video && !useVideo) {
      video.pause()
    }

    return () => {
      if (video && useVideo) {
        video.removeEventListener("canplay", hasLoaded)
        window.removeEventListener("scroll", scrollStart)
        window.removeEventListener("scroll", scrollEnd)
      }
    }
  }, [useVideo])

  return (
    <div className={styles.plan}>
      <div
        className={cn(styles.planLoader, {
          [styles.planLoaderHasloaded]: loaded,
        })}
      >
        <div
          className={cn(styles.planLoaderPlayer, {
            [styles.planLoaderPlayerHasloaded]: loaded,
          })}
        >
          <lottie-player
            src="../../lottie/loader.json"
            background="transparent"
            speed="1"
            autoPlay
            loop
          />
        </div>
      </div>

      <div className={cn(styles.planGroup, "gsap-plan-group")}>
        <div className={styles.planInner}>
          <video
            ref={asset}
            className={cn(
              styles.planAsset,
              { [styles.planAssetHidden]: !useVideo },
              "gsap-plan-asset-video"
            )}
            playsInline
            autoPlay
            muted
            loop
          >
            <source src="../../videos/plan.mp4" type="video/mp4" />
            <source src="../../videos/plan.webm" type="video/webm" />
          </video>

          <img
            className={cn(
              styles.planAsset,
              { [styles.planAssetHidden]: useVideo },
              "gsap-plan-asset-image"
            )}
            src="../../images/plan.png"
            alt="Welcome to the State of European Tech 2021"
          />

          {/* {useVideo ? (
            <video
              ref={asset}
              className={cn(styles.planAsset, "gsap-plan-asset")}
              playsInline
              autoPlay
              muted
              loop
            >
              <source src="../../videos/plan.mp4" type="video/mp4" />
              <source src="../../videos/plan.webm" type="video/webm" />
            </video>
          ) : (
            <img
              className={cn(styles.planAsset, "gsap-plan-asset")}
              src="../../images/plan.png"
              alt="Welcome to the State of European Tech 2021"
            />
          )} */}
        </div>

        <div className={styles.planMask}>
          <FlyWheel />
        </div>
      </div>
    </div>
  )
}

Plan.propTypes = {
  useVideo: PropTypes.bool,
}

Plan.defaultProps = {
  useVideo: true,
}

export default Plan
