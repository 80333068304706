import React, { useRef, useEffect, useState } from "react"
import { Link } from "gatsby"
import cn from "classnames"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import AvatarImage from "../AvatarImage/index.js"
import AvatarWall from "../AvatarWall/index.js"
import HeadlineSection from "../HeadlineSection/index.js"
import LogosPartners from "../LogosPartners/index.js"
import LogosSupporters from "../LogosSupporters/index.js"
import LogoWall from "../LogoWall/index.js"
import LogoImage from "../LogoImage/index.js"
import Panel from "../Panel/index.js"
import PanelButton from "../PanelButton/index.js"
import PanelSection from "../PanelSection/index.js"
import PanelTitle from "../PanelTitle/index.js"
import Plan from "../Plan/index.js"
import ScrollPrompt from "../ScrollPrompt/index.js"
import SectionPagination from "../SectionPagination/index.js"
import SidewaysImage from "../SidewaysImage/index.js"
import VideoSection from "../VideoSection/index.js"
import VimeoIframe from "../VimeoIframe/index.js"
import * as styles from "./index.module.css"

gsap.registerPlugin(ScrollTrigger)
gsap.config({
  force3D: true, // https://greensock.com/docs/v3/GSAP/gsap.config()
})

const getScale = (radius, offset = 0) => {
  const { innerWidth, innerHeight } = window
  const container = Math.max(innerWidth, innerHeight)
  const square = Math.sqrt(radius * radius * 2) - offset
  const difference = Math.max(container, square) / Math.min(container, square)
  const roundedUp = Math.round((difference + Number.EPSILON) * 100) / 100

  return roundedUp
}

const refreshScrollTrigger = () => {
  ScrollTrigger.refresh()
}

const Introduction = ({ ...props }) => {
  const app = useRef(null)
  const [activeSection, setActiveSection] = useState(-1)
  const [minSmall, setMinSmall] = useState(false)

  useEffect(() => {
    const element = app.current
    const assetSize = 1350 // Must align to CSS variable too.
    const gsapItems = gsap.utils.toArray(`[class*='gsap']`)
    const introduction = element.querySelector(".gsap-introduction")
    const sections = gsap.utils.toArray(".gsap-panel-section")
    const sectionPagination = element.querySelector(".gsap-section-pagination")
    const scrollPrompt = element.querySelector(".gsap-scroll-prompt")
    const planGroup = element.querySelector(".gsap-plan-group")
    const planAssets = element.querySelectorAll(
      ".gsap-plan-asset-image, .gsap-plan-asset-video"
    )
    const planPosition = element.querySelector(".gsap-plan-position")
    const flywheelImages = gsap.utils.toArray(".gsap-flywheel-image")
    const flywheelTitles = gsap.utils.toArray(".gsap-flywheel-title")

    ScrollTrigger.saveStyles(gsapItems)

    ScrollTrigger.matchMedia({
      // Small devices.
      "(max-width: 899px)": function () {
        const mapStages = [
          {},
          { scale: 2 },
          { x: "60%", y: "-45%" },
          { x: "60%", y: "50%" },
          { x: "-60%", y: "50%" },
          { x: "-60%", y: "-50%" },
          { x: "0%", y: "50%", scale: 4 },
        ]

        const indicatorStages = [
          [{ rotate: 0 }, { rotate: 90 }],
          [{ rotate: 90 }, { rotate: 180 }],
          [{ rotate: 180 }, { rotate: 270 }],
          [{ rotate: 270 }, { rotate: 360 }],
          [{ rotate: 0 }, { rotate: 90 }],
          [{ rotate: 90 }, { rotate: 180 }],
          [{ rotate: 180 }, { rotate: 270 }],
        ]

        gsap.set(planAssets, { x: "0%", y: "50%", z: 1, scale: 4 })

        sections.forEach((section, sectionIndex) => {
          const indicator = section.querySelector(".gsap-indicator")
          const items = section.querySelectorAll(
            ".gsap-panel-h1, .gsap-panel-h2, .gsap-panel-p, .gsap-panel-title, .gsap-panel-button, .gsap-logo-partners, .gsap-logo-supporters"
          )

          const itemTL = gsap.timeline({
            scrollTrigger: {
              // markers: true,
              trigger: section,
              start: "top 85%",
              end: "top 15%",
              scrub: 1,
            },
          })

          items.forEach((item, itemIndex) => {
            itemTL.fromTo(
              item,
              {
                opacity: 0,
                y: 80,
              },
              {
                opacity: 1,
                y: 0,
              },
              itemIndex === 0 ? 0 : `<25%`
            )
          })

          const mapTL = gsap.timeline({
            scrollTrigger: {
              // markers: true,
              id: `indicator-${sectionIndex}`,
              trigger: section,
              start: "top bottom",
              end: "top 50%",
              scrub: 1,
            },
          })

          const [rotationFrom, rotationTo] = indicatorStages[sectionIndex]
          const mapStyles = mapStages[sectionIndex] || {}

          if (indicator) {
            mapTL
              .fromTo(indicator, rotationFrom || {}, rotationTo || {})
              .to(planAssets, mapStyles, 0)
          } else {
            mapTL.to(planAssets, mapStyles, 0)
          }
        })
      },
      // Large devices.
      "(min-width: 900px)": function () {
        // Must reconcile with the CSS on '.introduction-content'
        const sectionHeight = window.innerHeight
        const stageDefaults = { duration: 1, ease: "power4.inOut" }
        const itemDefaults = { duration: 1, ease: "power4.out" }
        const staggerDefaults = { each: 0.1 }

        const mapStages = [
          { scale: 1, x: "0%", y: "0%", ...stageDefaults },
          { scale: 1.3, x: "0%", y: "0%", ...stageDefaults },
          { x: "45%", y: "-25%", ...stageDefaults },
          { x: "45%", y: "20%", ...stageDefaults },
          { x: "-35%", y: "20%", ...stageDefaults },
          { x: "-35%", y: "-25%", ...stageDefaults },
          { scale: 1, x: "0%", y: "0%", ...stageDefaults },
        ]

        const positionStages = [
          { rotate: 180, opacity: 0, scale: 0.9, ...stageDefaults },
          {
            rotate: 0,
            opacity: 1,
            scale: 1,
            ...stageDefaults,
          },
          { rotate: -45, ...stageDefaults },
          { rotate: -90, ...stageDefaults },
          { rotate: -135, ...stageDefaults },
          { rotate: -180, ...stageDefaults },
          { rotate: -225, ...stageDefaults },
        ]

        const flywheelStages = [
          { rotate: 0, opacity: 1, ...stageDefaults },
          { rotate: 0, opacity: 1, ...stageDefaults },
          { rotate: -180, opacity: 1, ...stageDefaults },
          { rotate: -90, opacity: 1, ...stageDefaults },
          { rotate: -0, opacity: 1, ...stageDefaults },
          { rotate: 90, opacity: 1, ...stageDefaults },
          { rotate: -355, opacity: 1, ...stageDefaults },
        ]

        // #####################
        // PINNING
        // #####################
        ScrollTrigger.create({
          // markers: true,
          id: "main",
          trigger: ".gsap-introduction-container",
          start: "top top",
          end: `+=${sectionHeight * (sections.length + 1)}px`,
          pin: true,
          pinSpacing: true,
          anticipatePin: 1,
          scrub: true,
        })

        // #####################
        // FlyWheel INTRO
        // #####################

        // Radius of the asset and the offset.
        // 50 is for the offset of the inner square of the circle.
        const scaleValue = getScale(assetSize / 2, 50)

        gsap.set(planGroup, { x: "-50%", y: "-50%", scale: scaleValue })
        gsap.set(planPosition, {
          transformOrigin: "50% 50%",
          rotate: -180,
          opacity: 0,
          scale: 0.9,
        })

        const introTL = gsap.timeline({
          scrollTrigger: {
            // markers: true,
            id: "intro",
            trigger: introduction,
            start: "top top",
            end: `+=${sectionHeight}px`,
            scrub: 3,
          },
        })

        const planBuffer = 100
        const planEndX = -Math.abs(assetSize / 2 - planBuffer + assetSize / 2)

        introTL
          .fromTo(scrollPrompt, { opacity: 1, y: 0 }, { opacity: 0, y: "100%" })
          .to(planGroup, { scale: 1, ...stageDefaults }, "<25%")
          .to(planGroup, { x: planEndX, ...stageDefaults })
          .fromTo(
            sectionPagination,
            { opacity: 0, x: "100%" },
            { opacity: 1, x: 0 },
            "<25%"
          )

        // #####################
        // SECTIONS
        // #####################
        sections.forEach((section, sectionIndex) => {
          const mapStyle = mapStages[sectionIndex] || {}
          const positionStyle = positionStages[sectionIndex] || {}
          const flywheelStyle = flywheelStages[sectionIndex] || {}

          const items = section.querySelectorAll(
            ".gsap-panel-h1, .gsap-panel-h2, .gsap-panel-p, .gsap-panel-title, .gsap-panel-button, .gsap-logo-partners, .gsap-logo-supporters"
          )
          const startY =
            sectionIndex === 0
              ? sectionHeight
              : sectionHeight * sectionIndex + sectionHeight

          const sectionTL = gsap.timeline({
            scrollTrigger: {
              // markers: true,
              id: `section-${sectionIndex}`,
              trigger: section,
              start: `+=${startY} top`,
              end: () => `+=${sectionHeight}`,
              scrub: 1.5,
              onEnter: async () => {
                // console.log("onEnter", sectionIndex)
                setActiveSection(sectionIndex)
              },
              onEnterBack: async () => {
                // console.log("onEnterBack", sectionIndex)
                setActiveSection(sectionIndex)
              },
              onLeave: () => {
                // console.log("onLeave", sectionIndex)
                if (sectionIndex === sections.length - 1) {
                  setActiveSection(-1)
                }
              },
              onLeaveBack: () => {
                // console.log("onLeaveBack", sectionIndex)
                if (sectionIndex === 0) {
                  setActiveSection(-1)
                }
              },
            },
          })

          const wheel =
            sectionIndex > 0 ? flywheelImages[sectionIndex - 1] : null
          const title =
            sectionIndex > 0 ? flywheelTitles[sectionIndex - 1] : null
          const els = title ? [title, items] : items

          sectionTL.to(planPosition, positionStyle).to(planAssets, mapStyle, 0)

          if (wheel) {
            sectionTL.to(wheel, flywheelStyle, 0)
          }

          sectionTL
            .fromTo(
              els,
              { opacity: 0, y: 80 },
              {
                opacity: 1,
                y: 0,
                stagger: { ...staggerDefaults },
                ...itemDefaults,
              },
              0
            )
            .to(els, {
              opacity: 0,
              y: -80,
              stagger: { ...staggerDefaults },
              ...itemDefaults,
            })
        })

        // #####################
        // FlyWheel OUTRO
        // #####################
        const outroTL = gsap.timeline({
          scrollTrigger: {
            // markers: true,
            id: "outro",
            trigger: introduction,
            start: `${sectionHeight * (sections.length + 1)}px`,
            end: `+=${sectionHeight}px`,
            scrub: 3,
          },
        })

        outroTL
          .to(planPosition, {
            rotate: -360,
            opacity: 0,
            scale: 0.9,
            ...stageDefaults,
          })
          .to(scrollPrompt, { opacity: 0, y: "-50%" }, "<25%")
          .to(sectionPagination, { opacity: 0, x: "100%" }, "<25%")
          .to(planGroup, { x: "-50%", ...stageDefaults }, "<25%")
          .to(planGroup, { scale: scaleValue, ...stageDefaults })
      },
    })

    const resizeHandler = () => {
      setMinSmall(window.matchMedia("(min-width: 900px)").matches)
      refreshScrollTrigger()

      return
    }

    resizeHandler()

    window.addEventListener("resize", resizeHandler)
    window.addEventListener("resize", refreshScrollTrigger)

    return () => {
      window.removeEventListener("resize", resizeHandler)
      window.removeEventListener("resize", refreshScrollTrigger)
    }
  }, [])

  return (
    <>
      <div ref={app} {...props}>
        <div className={cn(styles.introduction, "gsap-introduction")}>
          <div className={styles.introductionElements}>
            <Plan useVideo={!minSmall} />

            <div className={styles.introductionPagination}>
              <SectionPagination amount={7} active={activeSection} />
            </div>

            <div className={styles.introductionPrompt}>
              <ScrollPrompt
                src="../../images/scroll.svg"
                alt="Scroll to explore"
              />
            </div>
          </div>

          <div
            className={cn(
              styles.introductionContainer,
              "gsap-introduction-container"
            )}
          >
            <PanelSection active={activeSection === 0}>
              <Panel type="hero" indicator={false}>
                <div className={cn(styles.introductionPanelItemAbove)}>
                  <LogosPartners
                    row={minSmall}
                    text={"in partnership with"}
                    logo1={
                      <Link to="/chapter/soet-community/article/about-atomico/">
                        <img
                          src="../../logos/atomic.svg"
                          width={minSmall ? 110 : 220}
                          alt="Atomico"
                        />
                      </Link>
                    }
                    logo2={
                      <Link to="/chapter/word-our-sponsors/article/a-word-from-slush/">
                        <img
                          src="../../logos/slush.svg"
                          width={minSmall ? 70 : 150}
                          alt="Slush"
                        />
                      </Link>
                    }
                  />
                </div>

                <h1 className={"gsap-panel-h1"}>
                  Welcome to the State of European Tech 2021
                </h1>

                <p className={"gsap-panel-p"}>
                  The definitive take on European tech
                </p>

                <div className={cn(styles.introductionPanelItemBelow)}>
                  <LogosSupporters
                    text={"Proudly supported by"}
                    logo1={
                      <Link to="/chapter/word-our-sponsors/article/word-orrick/">
                        <img
                          src="../../logos/orrick.svg"
                          width="65"
                          alt="Orrick"
                        />
                      </Link>
                    }
                    logo2={
                      <Link to="/chapter/word-our-sponsors/article/word-silicon-valley-bank/">
                        <img
                          src="../../logos/svb.svg"
                          width="65"
                          alt="Silicon Valley Bank"
                        />
                      </Link>
                    }
                    logo3={
                      <Link to="/chapter/word-our-sponsors/article/word-baillie-gifford/">
                        <img
                          src="../../logos/baillie-gifford.svg"
                          width="110"
                          alt="Baillie Gifford"
                        />
                      </Link>
                    }
                  />
                </div>
              </Panel>
            </PanelSection>

            <PanelSection active={activeSection === 1}>
              <Panel>
                <PanelTitle hidden={minSmall}>
                  As the European tech sector grows,{" "}
                  <strong>the flywheel is spinning faster</strong>
                </PanelTitle>

                <h2 className={"gsap-panel-h2"}>
                  Record growth drives new milestones
                </h2>

                <p className={"gsap-panel-p"}>
                  Europe is firmly positioned as a global tech player in 2021,
                  with a record $100B of capital invested, 98 new unicorns, and
                  the strongest ever startup pipeline, now on par with the US.
                  European tech is creating value at its fastest pace, adding $1
                  trillion in just 8 months. While geographical differences in
                  maturity level remain, talent mobility and distributed success
                  is powering newer hubs.
                </p>

                <PanelButton to="/chapter/europe-global-tech-force/">
                  Europe, a global tech force
                </PanelButton>
              </Panel>
            </PanelSection>

            <PanelSection active={activeSection === 2}>
              <Panel>
                <PanelTitle hidden={minSmall}>
                  As the tech industry grows, so does{" "}
                  <strong>the talent pool</strong>
                </PanelTitle>

                <h2 className={"gsap-panel-h2"}>Talent is betting on tech</h2>

                <p className={"gsap-panel-p"}>
                  The European tech talent pool is deeper and more experienced
                  than ever, as talent is recycled across the continent. Yet
                  there is still a way to go; talent acquisition tops the list
                  of challenges for founders, alongside fundraising. Many
                  founders – particularly those from underrepresented
                  backgrounds – are finding it as hard as ever to access
                  capital.
                </p>

                <PanelButton to="/chapter/founders-leaders/">
                  Founders & Leaders
                </PanelButton>
              </Panel>
            </PanelSection>

            <PanelSection active={activeSection === 3}>
              <Panel>
                <PanelTitle hidden={minSmall}>
                  A strong talent pool leads to{" "}
                  <strong>better ideas and better companies</strong>
                </PanelTitle>

                <h2 className={"gsap-panel-h2"}>
                  European entrepreneurs are shaping their own path
                </h2>

                <p className={"gsap-panel-p"}>
                  European tech has become a breeding ground for companies
                  across all sectors. From frontier tech to crypto and
                  enterprise SaaS, European founders can build successful
                  companies from Europe. A new generation of entrepreneurs is
                  putting social and climate impact at the core of their
                  mission. The ecosystem is aware of the need to improve
                  diversity and inclusion, but has much left to do to make that
                  happen.
                </p>

                <PanelButton to="/chapter/better-ideas-better-companies/">
                  Better ideas, better companies
                </PanelButton>
              </Panel>
            </PanelSection>

            <PanelSection active={activeSection === 4}>
              <Panel>
                <PanelTitle hidden={minSmall}>
                  Better ideas and companies attract more{" "}
                  <strong>world class investors...</strong>
                </PanelTitle>

                <h2 className={"gsap-panel-h2"}>
                  Investing in Europe is more attractive and dynamic than ever
                </h2>

                <p className={"gsap-panel-p"}>
                  VC has become the leading funding mechanism for entrepreneurs,
                  but to stay competitive, VCs have to keep innovating. As the
                  opportunity set matures, global investors are doubling down:
                  from seed rounds to public markets, there are now more
                  international investors and buyers active in Europe. While
                  investors across the board have more conviction in European
                  tech, pension funds still lag behind on their allocation to
                  tech.
                </p>

                <PanelButton to="/chapter/attracting-world-class-investors/">
                  World-class investors
                </PanelButton>
              </Panel>
            </PanelSection>

            <PanelSection active={activeSection === 5}>
              <Panel>
                <PanelTitle hidden={minSmall}>
                  The best investors support{" "}
                  <strong>the strongest outcomes...</strong>
                </PanelTitle>

                <h2 className={"gsap-panel-h2"}>
                  Outcomes defy expectations in private and public markets
                </h2>

                <p className={"gsap-panel-p"}>
                  Europe continues to produce more tech IPOs than the US, $1B+
                  IPOs are becoming the norm, and record-breaking exit activity
                  reached an astonishing $275B in deal value. Still, Europe is
                  only in the first innings of its tech journey, with all
                  indicators now pointing towards many trillions in value to be
                  added over the next decade, even in a conservative scenario.
                </p>

                <PanelButton to="/chapter/extraordinary-outcomes/">
                  Extraordinary outcomes
                </PanelButton>
              </Panel>
            </PanelSection>

            <PanelSection active={activeSection === 6}>
              <Panel>
                <PanelTitle hidden={minSmall}>
                  These outcomes in turn catalyse{" "}
                  <strong>more investment, talent, and ideas...</strong>
                </PanelTitle>

                <h2 className={"gsap-panel-h2"}>
                  From stumbling blocks to building blocks
                </h2>
                <p className={"gsap-panel-p"}>
                  European tech is on a strong trajectory, with venture capital
                  delivering consistently benchmark-beating returns. However,
                  funding, talent and policy are all critical components we must
                  continue to fine tune. With more collaboration across private
                  and public sectors, we can supercharge the next decade for
                  tech. And with better accountability from founders and
                  investors, we can deliver more on inclusivity and
                  sustainability.
                </p>

                <PanelButton to="/chapter/how-can-flywheel-spin-faster/">
                  How can the flywheel spin faster?
                </PanelButton>
              </Panel>
            </PanelSection>
          </div>

          <div className={styles.introductionContent}>
            <div className={styles.introductionContentInner}>
              <img
                src="../../images/soet21.svg"
                width={minSmall ? 280 : 160}
                alt="State Of European Tech 21"
                className={styles.introductionLogo}
              />

              <p>is proudly brought to you by</p>

              <div className={styles.introductionLogoArea}>
                <LogosPartners
                  text={"in partnership with"}
                  logo1={
                    <Link to="/chapter/soet-community/article/about-atomico/">
                      <img
                        src="../../logos/atomic-dark.svg"
                        width={minSmall ? 305 : 110}
                        alt="Atomico"
                      />
                    </Link>
                  }
                  logo2={
                    <Link to="/chapter/word-our-sponsors/article/a-word-from-slush/">
                      <img
                        src="../../logos/slush-dark.svg"
                        width={minSmall ? 220 : 150}
                        alt="Slush"
                      />
                    </Link>
                  }
                />

                <LogosSupporters
                  text={"Proudly supported by"}
                  logo1={
                    <Link to="/chapter/word-our-sponsors/article/word-orrick/">
                      <img
                        src="../../logos/orrick-dark.svg"
                        width={minSmall ? 115 : 65}
                        alt="Orrick"
                      />
                    </Link>
                  }
                  logo2={
                    <Link to="/chapter/word-our-sponsors/article/word-silicon-valley-bank/">
                      <img
                        src="../../logos/svb-dark.svg"
                        width={minSmall ? 115 : 65}
                        alt="Silicon Valley Bank"
                      />
                    </Link>
                  }
                  logo3={
                    <Link to="/chapter/word-our-sponsors/article/word-baillie-gifford/">
                      <img
                        src="../../logos/baillie-gifford-dark.svg"
                        width={minSmall ? 180 : 110}
                        alt="Baillie Gifford"
                      />
                    </Link>
                  }
                />
              </div>

              <h3>This report would not be possible without...</h3>
              <p>Our data partners</p>

              <div className={styles.introductionContentArea}>
                <LogoWall>
                  {[
                    { name: "Dealroom", file: "Dealroom.png" },
                    { name: "Invest Europe", file: "Invest-Europe.png" },
                    { name: "S&P Global", file: "Global.png" },
                    {
                      name: "Extend Ventures",
                      file: "Extend-Ventures.png",
                    },
                    { name: "Grape Data", file: "Grape-Data.png" },
                    { name: "Politico", file: "Politico.png" },
                    { name: "Pitch Deck", file: "Pitch-Deck.png" },
                    { name: "Craft", file: "Craft.png" },
                    { name: "Indeed", file: "Indeed.png" },
                    { name: "Option Impact", file: "Option-Impact.png" },
                    { name: "Landscape", file: "Landscape.png" },
                    { name: "Google", file: "Google.png" },
                    { name: "Mapverse", file: "Mapverse.png" },
                    { name: "Sifted", file: "Sifted.png" },
                    { name: "ESG VC", file: "ESG-VC.png" },
                    { name: "Venture ESG", file: "Venture-ESG.png" },
                  ].map((image, i) => (
                    <LogoImage
                      key={i}
                      src={`../../partners/${image.file}`}
                      alt={image.name}
                    />
                  ))}
                </LogoWall>
              </div>

              <p>
                Our industry voices
                <br />
                (more than 60 of you!)
              </p>

              <div className={styles.introductionContentArea}>
                <AvatarWall>
                  {[
                    { name: "Alex Ferrara", file: "Alex-Ferrara.jpg" },
                    {
                      name: "Alex Shelkovnikov",
                      file: "Alex-Shelkovnikov.jpg",
                    },
                    { name: "Alice Bentinck", file: "Alice-Bentinck.jpg" },
                    { name: "Anne Boden", file: "Anne-Boden.jpg" },
                    { name: "Brandon Deer", file: "Brandon-Deer.jpg" },
                    { name: "Check Warner", file: "Check-Warner.jpg" },
                    {
                      name: "Dame Stephanie Shirley",
                      file: "Dame-Stephanie-Shirley.jpg",
                    },
                    { name: "Dan Lupu", file: "Dan-Lupu.jpg" },
                    { name: "Daniel Wiegand", file: "Daniel-Wiegand.jpg" },
                    { name: "Eric Collins", file: "Eric-Collins.jpg" },
                    { name: "Erin Platts", file: "Erin-Platts.jpg" },
                    { name: "Gesa Miczaika", file: "Gesa-Miczaika.jpg" },
                    { name: "Gregory Ganev", file: "Gregory-Ganev.jpg" },
                    { name: "Guillaume Pousaz", file: "Guillaume-Pousaz.jpg" },
                    { name: "Heidi Lindvall", file: "Heidi-Lindvall.jpg" },
                    { name: "Hiro Tamura", file: "Hiro-Tamura.jpg" },
                    { name: "Hung Dang", file: "Hung-Dang.jpg" },
                    { name: "Ilkka Paananen", file: "Ilkka-Paananen.jpg" },
                    { name: "Irina Haivas", file: "Irina-Haivas.jpg" },
                    { name: "Izabel Jelenic", file: "Izabel-Jelenic.jpg" },
                    { name: "Jakub Jurovych", file: "Jakub-Jurovych.jpg" },
                    { name: "Janneke Niessen", file: "Janneke-Niessen.jpg" },
                    { name: "Jean David Malo", file: "Jean-David-Malo.jpg" },
                    { name: "John Collison", file: "John-Collison.jpg" },
                    { name: "Juan Urdiales", file: "Juan-Urdiales.jpg" },
                    { name: "Judith Dada", file: "Judith-Dada.jpg" },
                    { name: "Karl Lokko", file: "Karl-Lokko.jpg" },
                    { name: "Kim Fai Kok", file: "Kim-Fai-Kok.jpg" },
                    { name: "Laura Connell", file: "Laura-Connell.jpg" },
                    { name: "Leo Patrick", file: "Leo-Patrick.jpg" },
                    { name: "Lila Ibrahim", file: "Lila-Ibrahim.jpg" },
                    { name: "Luciana Lixandru", file: "Luciana-Lixandru.jpg" },
                    { name: "Maren Bannon", file: "Maren-Bannon.jpg" },
                    {
                      name: "Margrethe Vestager",
                      file: "Margrethe-Vestager.jpg",
                    },
                    { name: "Maria Raga", file: "Maria-Raga.jpg" },
                    { name: "Mariya Gabriel", file: "Mariya-Gabriel.jpg" },
                    { name: "Max Rimpel", file: "Max-Rimpel.jpg" },
                    { name: "Michelle Kennedy", file: "Michelle-Kennedy.jpg" },
                    { name: "Mika Lintila", file: "Mika-Lintila.jpg" },
                    { name: "Nader AlSalim", file: "Nader-AlSalim.jpg" },
                    { name: "Nathan Benaich", file: "Nathan-Benaich.jpg" },
                    { name: "Nicolas Julia", file: "Nicolas-Julia.jpg" },
                    { name: "Nicole Quinn", file: "Nicole-Quinn.jpg" },
                    { name: "Nigel Toon", file: "Nigel-Toon.jpg" },
                    { name: "Osnat Michaeli", file: "Osnat-Michaeli.jpg" },
                    { name: "Ott Kaukver", file: "Ott-Kaukver.jpg" },
                    {
                      name: "Philippe Huberdeau",
                      file: "Philippe-Huberdeau.jpg",
                    },
                    { name: "Rachael Corson", file: "Rachael-Corson.jpg" },
                    { name: "Renata Bandov", file: "Renata-Bandov.jpg" },
                    { name: "Robert Lacher", file: "Robert-Lacher.jpg" },
                    { name: "Rodney Appiah", file: "Rodney-Appiah.jpg" },
                    { name: "Romanie Thomas", file: "Romanie-Thomas.jpg" },
                    { name: "Sasha Astafyeva", file: "Sasha-Astafyeva.jpg" },
                    {
                      name: "Sasha Vidiborskiy",
                      file: "Sasha-Vidiborskiy.jpg",
                    },
                    { name: "Sia Houchangnia", file: "Sia-Houchangnia.jpg" },
                    { name: "Sofia Benjumea", file: "Sofia-Benjumea.jpg" },
                    { name: "Sonali De Rycker", file: "Sonali-De-Rycker.jpg" },
                    { name: "Sonya Iovieno", file: "Sonya-Iovieno.jpg" },
                    { name: "Stephen Lowery", file: "Stephen-Lowery.jpg" },
                    { name: "Steve O_Hear", file: "Steve-O_Hear.jpg" },
                    { name: "Tariq Rauf", file: "Tariq-Rauf.jpg" },
                    { name: "Thomas Moon", file: "Thomas-Moon.jpg" },
                  ].map((image, i) => (
                    <AvatarImage
                      key={i}
                      src={`../../avatars/${image.file}`}
                      alt={image.name}
                    />
                  ))}
                </AvatarWall>
              </div>

              <p>
                The European tech community
                <br />
                (more than 5,000 of you took the survey)
              </p>
            </div>

            <SidewaysImage src="../../images/respondants.svg" alt="People" />

            <HeadlineSection text="Thank you to the entire State of European Tech Community!" />

            <VideoSection
              headline="Watch the State of European Tech 2021 launch"
              text="Read on... we have 500+ insights to share with you. We hope you enjoy it."
            >
              <VimeoIframe
                src="https://player.vimeo.com/video/654207775?h=c818357ed4&title=0&byline=0&portrait=0"
                title="The State of European Tech 2021"
                description="Report co-authors Tom Wehmeier and Sarah Guemouri walk you through all the key findings fit to print from this year's report including the hype, the highlights and the hardships that made up a record-breaking year for the European technology ecosystem."
              />

              <VimeoIframe
                src="https://player.vimeo.com/video/654207996?h=0dcbe7a124&title=0&byline=0&portrait=0"
                title="The State of European Tech 2021: Finland&#039;s Flywheel Delivers the Goods"
                description="Supercell Co-Founder and CEO Ilkka Paananen speaks to Wolt co-founder Miki Kuusi, COO Riku Mäkelä and VP of New Markets, Marianne Vikkula, about how they turned a hyperlocal delivery service into a $8 billion European tech success."
              />

              <VimeoIframe
                src="https://player.vimeo.com/video/654208615?h=3de62b8809&title=0&byline=0&portrait=0"
                title="The State of European Tech 2021: Black Seed&rsquo;s Co-Founder Karl Lokko on turning pain into progress"
                description="Karl Lokko sits down with Atomico Partner Hiro Tamura in Brixton to share his vision of how tech investment can change lives by empowering black entrepreneurs."
              />

              <VimeoIframe
                src="https://player.vimeo.com/video/654208776?h=ae704fd7e2&title=0&byline=0&portrait=0"
                title="The State of European Tech 2021: Europe&#039;s first female unicorn founder, Corinne Vigreux, on perseverance and paying it forward"
                description="TomTom co-founder & CMO, Just Eat Takeaway & Group Iliad board member and CODAM founder Corinne Vigreux sits down with Capital T Founding Partner (and repeat entrepreneur) Janneke Niessen to discuss perseverance, paying it forward and the progress of the European tech ecosystem this year."
              />

              <VimeoIframe
                src="https://player.vimeo.com/video/654209010?h=5865beee20&title=0&byline=0&portrait=0"
                title="The State of European Tech 2021: How Klarna became Europe&#039;s Most Valuable Private Tech Company"
                description="Klarna co-founder and CEO Sebastian Siemiatkowski sits down with Atomico co-founder and CEO Niklas Zennström to discuss how the company evolved into a global category leader and why ‘Buy Now Pay Later’ is only part of the story."
              />

              <VimeoIframe
                src="https://player.vimeo.com/video/654216900?h=4a36f323d9&title=0&byline=0&portrait=0"
                title="The State of European Tech 2021: Tony Blair - Tech, Brussels &amp; Beyond - An Insider&rsquo;s View for European Founders"
                description="Tony Blair, former UK Prime Minister and Executive Chairman of the Institute for Global Change, sat down with Linda Griffin, VP of Global Policy for European healthcare unicorn Kry / Livi. They discussed why tech policy remains such a challenge, which direction it should take in the future, and how entrepreneurs can have their voices heard."
              />
            </VideoSection>
          </div>
        </div>
      </div>
    </>
  )
}

export default Introduction
