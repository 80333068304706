import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import HighlightTitle from "../HighlightTitle/index.js"
import * as styles from "./index.module.css"

const PanelTitle = ({ children, hidden }) => {
  const [classy, setClassy] = useState(hidden)

  useEffect(() => {
    setClassy(hidden ? styles.panelTitleHidden : "")
  }, [hidden])

  return (
    <div className={cn(styles.panelTitle, classy, "gsap-panel-title")}>
      <HighlightTitle>{children}</HighlightTitle>
    </div>
  )
}

PanelTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hidden: PropTypes.bool,
}

PanelTitle.defaultProps = {
  children: null,
  hidden: false,
}

export default PanelTitle
