// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-search-articles-js": () => import("./../../../src/pages/search-articles.js" /* webpackChunkName: "component---src-pages-search-articles-js" */),
  "component---src-pages-search-data-js": () => import("./../../../src/pages/search-data.js" /* webpackChunkName: "component---src-pages-search-data-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-chapter-js": () => import("./../../../src/templates/chapter.js" /* webpackChunkName: "component---src-templates-chapter-js" */),
  "component---src-templates-standalone-article-js": () => import("./../../../src/templates/standaloneArticle.js" /* webpackChunkName: "component---src-templates-standalone-article-js" */),
  "component---src-templates-standalone-chart-js": () => import("./../../../src/templates/standaloneChart.js" /* webpackChunkName: "component---src-templates-standalone-chart-js" */)
}

