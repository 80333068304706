import React from "react";
import NavTabContext, { NAV_TABS } from "../context";
import MainContents from "../MainContents";
import PreviousReports from "../PreviousReports";
import Partners from "../Partners";
import styles from "./index.module.css";

const TabContent = () => {
  const { selectedTab } = React.useContext(NavTabContext);
  const InnerComponent =
    {
      [NAV_TABS.CONTENTS]: MainContents,
      [NAV_TABS.PREVIOUS_REPORTS]: PreviousReports,
      [NAV_TABS.PARTNERS]: Partners
    }[selectedTab] || null;

  return (
    <div
      className={
        InnerComponent === Partners ? styles.tabContentWhite : styles.tabContent
      }
    >
      <InnerComponent />
    </div>
  );
};

export default TabContent;
