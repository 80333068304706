import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import Grid, { Half, Thin } from "../Grid";
import Img from "gatsby-image";

const textStyling = {
  h3: styles.h3,
  h4: styles.h4,
  h5: styles.h5,
  h6: styles.h6,
  h6caps: styles.h6Caps,
  p1: styles.p1,
  p2: styles.p2,
  p3: styles.p3
};

const paddingStyling = {
  s: styles.smPadding,
  m: styles.mdPadding,
  l: styles.lgPadding
};

const keyLinePaddingStyling = {
  s: styles.smKeyLine,
  m: styles.mdKeyLine,
  l: styles.lgKeyLine
};

const BlockFreeTextText = ({ block }) => {
  const paddingStyle = block.textText.addKeyLine ? styles.noPadding : paddingStyling[block.textText.padding];

  const leftClassName =
    textStyling[block.textText.leftTextStyling] +
    (block.textText.leftBold ? ` ` + styles.bold : ``);

  const rightClassName =
    textStyling[block.textText.rightTextStyling] +
    (block.textText.rightBold ? ` ` + styles.bold : ``);

  let LeftGrid = Half;
  let RightGrid = Thin;
  if (block.textText.leftColumn === `third`) {
    LeftGrid = Thin;
    RightGrid = Half;
  }

  const leftText = block.textText.leftText.split(`\n`).map((item, idx) => {
    return (
      <>
        {item}
        <br />
      </>
    );
  });

  const rightText = block.textText.rightText.split(`\n`).map((item, idx) => {
    return (
      <>
        {item}
        <br />
      </>
    );
  });

  const leftDiv = (
    <LeftGrid id={`left-${block.id}`} className={styles.leftGrid}>
      <p className={leftClassName}>{leftText}</p>
    </LeftGrid>
  );
  const rightDiv = (
    <RightGrid id={`right-${block.id}`} className={styles.rightGrid}>
      <p className={rightClassName}>{rightText}</p>
    </RightGrid>
  );

  return (
    <Grid className={`${styles.root} ${paddingStyle} `}>
      {leftDiv}
      {rightDiv}
      {block.textText.addKeyLine && <hr className={keyLinePaddingStyling[block.textText.padding]} />}
    </Grid>
  );
};

const BlockFreeTextImage = ({ block }) => {
  const paddingStyle = block.textImage.addKeyLine ? styles.noPadding : paddingStyling[block.textImage.padding];

  const leftClassName =
    textStyling[block.textImage.leftTextStyling] +
    (block.textImage.leftBold ? ` ` + styles.bold : ``);

  let LeftGrid = Half;
  let RightGrid = Thin;
  if (block.textImage.leftColumn === `third`) {
    LeftGrid = Thin;
    RightGrid = Half;
  }

  const leftText = block.textImage.leftText.split(`\n`).map((item, idx) => {
    return (
      <>
        {item}
        <br />
      </>
    );
  });

  const leftDiv = (
    <LeftGrid id={`left-${block.id}`} className={styles.leftGrid}>
      <p className={leftClassName}>{leftText}</p>
    </LeftGrid>
  );
  const rightDiv = (
    <RightGrid id={`right-${block.id}`} className={styles.rightGrid}>
      <Img fluid={block.textImage.rightImage.childImageSharp.fluid} />
    </RightGrid>
  );

  return (
    <Grid className={`${styles.root} ${paddingStyle}`}>
      {leftDiv}
      {rightDiv}
      {block.textImage.addKeyLine && <hr className={keyLinePaddingStyling[block.textImage.padding]} />}
    </Grid>
  );
};

const BlockFreeImageText = ({ block }) => {
  const paddingStyle = block.imageText.addKeyLine ? styles.noPadding : paddingStyling[block.imageText.padding];

  const rightClassName =
    textStyling[block.imageText.rightTextStyling] +
    (block.imageText.rightBold ? ` ` + styles.bold : ``);

  let LeftGrid = Half;
  let RightGrid = Thin;
  if (block.imageText.leftColumn === `third`) {
    LeftGrid = Thin;
    RightGrid = Half;
  }

  const rightText = block.imageText.rightText.split(`\n`).map((item, idx) => {
    return (
      <>
        {item}
        <br />
      </>
    );
  });

  const leftDiv = (
    <LeftGrid id={`left-${block.id}`} className={styles.leftGrid}>
      <Img fluid={block.imageText.leftImage.childImageSharp.fluid} />
    </LeftGrid>
  );

  const rightDiv = (
    <RightGrid id={`right-${block.id}`} className={styles.rightGrid}>
      <p className={rightClassName}>{rightText}</p>
    </RightGrid>
  );

  return (
    <Grid className={`${styles.root} ${paddingStyle}`}>
      {leftDiv}
      {rightDiv}
      {block.imageText.addKeyLine && <hr className={keyLinePaddingStyling[block.imageText.padding]} />}
    </Grid>
  );
};

const blockType = {
  textTextChild: BlockFreeTextText,
  textImageChild: BlockFreeTextImage,
  imageTextChild: BlockFreeImageText
};

const BlockFreeTextContainer = ({ blocks }) => {
  return <>{blocks.map(block => blockType[block.type]({ block }))}</>;
};

const StreamBlock = {
  id: PropTypes.string,
  stripped: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.object
};

BlockFreeTextContainer.propTypes = {
  blocks: PropTypes.arrayOf(StreamBlock)
};

export default BlockFreeTextContainer;
