import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import rightArrow from "./rightArrow.svg";
import rightArrowTeal from "./rightArrowTeal.svg";
import downloadIcon from "./downloadIcon.svg";
import emailIcon from "./emailIcon.svg";
import SVG from "../Svg";

import { Link } from "gatsby";

const PrimaryButton = ({
  onClick,
  children,
  disabled,
  type,
  arrowThin,
  downloadThin,
  email,
  fat,
  teal,
  to,
  inverted,
  dark,
  outline,
  arrow,
  download
}) => {
  const El = type === `link` || typeof to === `string` ? Link : `button`;
  let className = inverted ? styles.rootInverted : styles.root;
  className = dark ? styles.rootDark : className;
  className = arrowThin ? styles.rootThin : className;
  className = downloadThin ? styles.rootThinDownload : className;
  className = email ? styles.rootEmailThin : className;
  className = arrow ? styles.rootArrow : className;
  className = teal ? styles.rootArrowTeal : className;
  className = outline ? styles.rootOutline : className;
  return (
    <El className={className} onClick={onClick} disabled={disabled} to={to}>
      {arrow && <SVG className={styles.arrow} svg={rightArrow} />}
      {teal && <SVG className={styles.arrow} svg={rightArrowTeal} />}
      {arrowThin && <SVG className={styles.arrow} svg={rightArrow} />}
      {email && <SVG className={styles.arrow} svg={emailIcon} />}
      {download && <SVG className={styles.download} svg={downloadIcon} />}
      {children}
    </El>
  );
};

PrimaryButton.defaultProps = {
  disabled: false,
  type: `button`
};

PrimaryButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf([`button`, `link`, `email`]),
  style: PropTypes.string,
  to: PropTypes.string,
  arrowThin: PropTypes.bool,
  email: PropTypes.bool,
  inverted: PropTypes.bool,
  dark: PropTypes.bool,
  arrow: PropTypes.bool,
  download: PropTypes.bool,
  downloadThin: PropTypes.bool,
  outline: PropTypes.bool
};

export default PrimaryButton;
