import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import Grid, { Half } from "../Grid";

const youTubeUrlBuilder = url => {
  const id =
    url.searchParams.get(`v`) !== null
      ? url.searchParams.get(`v`)
      : url.pathname.split(`/`).slice(-1)[0];

  return `https://www.youtube.com/embed/${id}?modestbranding=1&disablekb=1&rel=0`;
};

const vimeoUrlBuilder = url => {
  const id = url.pathname.split(`/`).slice(-1)[0];
  return `https://player.vimeo.com/video/${id}?portrait=0&byline=0&title=0`;
};

const useEmbeddedUrl = url => {
  const parsedUrl = React.useMemo(() => new URL(url), [url]);

  const urlBuilder = {
    "youtube.com": youTubeUrlBuilder,
    "www.youtube.com": youTubeUrlBuilder,
    "vimeo.com": vimeoUrlBuilder,
    "player.vimeo.com": vimeoUrlBuilder
  }[parsedUrl.host];

  return urlBuilder(parsedUrl);
};

const BlockVideo = ({ title, text, videoUrl }) => {
  const embeddedUrl = useEmbeddedUrl(videoUrl);

  return (
    <Grid className={styles.video}>
      <Half>
        <div>
          <span className={styles.title}>{title}</span>
        </div>
        <div>
          <span className={styles.text}>{text}</span>
        </div>
      </Half>
      <Half>
        <div className={styles.frameContainer}>
          <iframe frameBorder={0} className={styles.frame} src={embeddedUrl} />
        </div>
      </Half>
    </Grid>
  );
};

BlockVideo.propTypes = {
  id: PropTypes.string
};

export default BlockVideo;
