import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";

import PrimaryButton from "../PrimaryButton";

const DownloadSuccess = ({ onOk, text }) => (
  <div className={styles.root}>
    <h1 className={styles.title}>Thank You</h1>
    <p className={styles.body}>
      {text ? (
        text
      ) : (
        <span>
          Your download will start in a few moments. Check your browser
          {`'`}s download folder.
        </span>
      )}
    </p>
    <div className={styles.button}>
      <PrimaryButton onClick={onOk} download={true}>
        OK
      </PrimaryButton>
    </div>
  </div>
);

DownloadSuccess.defaultProps = {
  email: `your address`
};

DownloadSuccess.propTypes = {
  // email: PropTypes.string,
  text: PropTypes.string,
  onOk: PropTypes.func
};

export default DownloadSuccess;
