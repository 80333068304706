import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import * as styles from "./index.module.css"

const LogosPartners = ({ row, text, logo1, logo2 }) => {
  const [classy, setClassy] = useState(row)

  useEffect(() => {
    setClassy(row ? styles.logosPartnersRow : "")
  }, [row])

  return (
    <div className={cn(styles.logosPartners, classy, "gsap-logo-partners")}>
      {logo1 && <div className={cn(styles.logosPartnersItem)}>{logo1}</div>}
      {text && <span className={cn(styles.logosPartnersText)}>{text}</span>}
      {logo2 && <div className={cn(styles.logosPartnersItem)}>{logo2}</div>}
    </div>
  )
}

LogosPartners.propTypes = {
  row: PropTypes.bool,
  text: PropTypes.string,
  logo1: PropTypes.node,
  logo2: PropTypes.node,
}

LogosPartners.defaultProps = {
  row: false,
  text: "",
  logo1: null,
  logo2: null,
}

export default LogosPartners
