import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import * as styles from "./index.module.css"

const HeadlineSection = ({ text }) => {
  return (
    <div className={cn(styles.headlineSection)}>
      {text && text.length && (
        <p className={cn(styles.headlineSectionText)}>{text}</p>
      )}
    </div>
  )
}

HeadlineSection.propTypes = {
  text: PropTypes.string,
}

HeadlineSection.defaultProps = {
  text: "",
}

export default HeadlineSection
