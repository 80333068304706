import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import * as styles from "./index.module.css"
import LinkButton from "../LinkButton/index.js"

const PanelButton = ({ children, ...props }) => {
  return (
    <div className={cn(styles.panelButton, "gsap-panel-button")}>
      <LinkButton {...props}>{children}</LinkButton>
    </div>
  )
}

PanelButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

PanelButton.defaultProps = {
  children: null,
}

export default PanelButton
