import React from "react";
import styles from "./index.module.css";
import NavContext from "../../NavContext";
import TopBarContext from "../../TopBar/context";
import MenuBurger from "../../MenuBurger";

const TogglePanel = () => {
  const { toggleReportNav, reportNavOpen } = React.useContext(NavContext);
  const { topBarBottom } = React.useContext(TopBarContext);

  return (
    <>
      <div className={styles.burger}>
        <MenuBurger/>
      </div>
      <div
        onClick={toggleReportNav}
        className={styles.togglePanel}
        style={{ top: `${topBarBottom}px` }}
      >
        <div className={styles.textContent}>
          {reportNavOpen ? `CLOSE` : `MENU`}
        </div>
      </div>
    </>
  );
};

export default TogglePanel;
