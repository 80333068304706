import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Seo from "../components/Seo";

import Article from "../components/Article";
import ArticleFooter from "../components/ArticleFooter";
import ReportLayout from "../components/ReportLayout";
import InfiniteArticles from "../components/InfiniteArticles";

class ArticleTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const article = data.soetArticle;
    const nextMap = data.allSoetArticle.edges.reduce((map, item) => {
      map[item.node.entityId] = { next: item.node.next, path: item.node.path };
      return map;
    }, {});

    let seoImage;
    let seoCanonicalPath;
    let seoPath = article.path;
    let seoTitle = article.seo_title;
    let seoDescription = article.subTitle || null;
    let seoOgTitle = seoTitle || article.title;
    let seoOgDescription = seoDescription;

    if (pageContext) {
      seoPath = pageContext.sharePath || article.path; // For linked in og:url, path must be to share also.

      if (pageContext.shareCanonical) {
        seoCanonicalPath = pageContext.shareCanonical;
      }
      if (pageContext.shareImage) {
        seoImage = pageContext.shareImage;
      }
      if (pageContext.shareTitle) {
        seoOgTitle = pageContext.shareTitle;
      }
      if (pageContext.shareDescription) {
        seoOgDescription = pageContext.shareDescription;
      }
    }

    return (
      <ReportLayout
        title={article.title}
        path={article.path}
        entityId={article.entityId}
      >
        <InfiniteArticles
          FooterComponent={ArticleFooter}
          article={article}
          nextMap={nextMap}
        >
          <Seo
            title={seoTitle}
            description={seoDescription}
            ogTitle={seoOgTitle}
            ogDescription={seoOgDescription}
            path={seoPath}
            image={seoImage}
            canonicalPath={seoCanonicalPath}
          />
          <Article
            path={article.path}
            entityId={article.entityId}
            articleType={article.type}
            titleNumber={article.titleNumber}
            title={article.title}
            seoTitle={article.title}
            sources={article.sources}
            blocks={article.blocks}
            next={article.next}
            illustrationImage={article.illustrationImage}
            insights={article.insights}
            summaryTitle={article.summaryTitle}
            summary={article.summary}
          />
        </InfiniteArticles>
      </ReportLayout>
    );
  }
}
ArticleTemplate.propTypes = {
  data: PropTypes.shape({
    soetArticle: PropTypes.shape({
      chapter: PropTypes.shape({
        title: PropTypes.string
      }),
      seoTitle: PropTypes.string,
      sources: PropTypes.arrayOf(PropTypes.string)
    })
  })
};

export default ArticleTemplate;

export const ChartBlockFragment = graphql`
  fragment ChartBlockFragment on ChartBlock {
    entityType
    priority
    chartId
    chartTitleNumber: titleNumber
    disableDataLabels
    disableLegend
    decimalPoints
    insightTitle
    insight
    insightBlock {
      title
      stat
      source
      description
    }
    insightBlock2 {
      title
      stat
      source
      description
    }
    width
    addKeyLine
    note
    prefix
    sources
    stacking
    suffix
    title
    type
    yAxisLabel
    yAxisLabel2
    xAxisLabel
    groupSeries
    showRegression
    colorAxisRange
    disableDataDownload
    lineSeriesPrefix
    lineSeriesSuffix
    lineSeriesDecimalPoints
    tableWrap
    sponsorLabel
    sponsorUrl
    groups {
      id
      name
      categories
      series {
        name
        data
        type
        yAxis
        zIndex
        stack
        yAxisLabels
        layoutAlgorithm
      }
    }
  }
`;

export const ChartImageBlockFragment = graphql`
  fragment ChartImageBlockFragment on ChartImageBlock {
    id
    svg {
      publicURL
      extension
    }
    title
    note
    insight
    entityType
    sources
    legend {
      color
      label
    }
    priority
    type
    chartId
    groupId
  }
`;

// eslint-disable-next-line no-undef
export const ArticleFieldsFragment = graphql`
  fragment ArticleFieldsFragment on soetArticle {
    entityId
    path
    pathStandalone
    next {
      entityId
      path
      pathStandalone
      title
      titleNumber
      type
      illustrationImage {
        childImageSharp {
          fluid(quality: 80, maxWidth: 340) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        extension
        publicURL
      }
    }
    sources
    title
    illustrationImage {
      childImageSharp {
        fluid(quality: 80, maxWidth: 340) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      extension
      publicURL
    }
    seo_title
    titleNumber
    type
    soetChapter {
      entityId
    }
    insights {
      title
      text
      source
    }
    summary
    summaryTitle
    blocks {
      ...ChartBlockFragment
      ...ChartImageBlockFragment
      ... on FreeTextContainerBlock {
        entityType
        priority
        blocks {
          ... on TextTextChildBlock {
            id
            type
            textText {
              addKeyLine
              leftBold
              leftColumn
              leftText
              leftTextStyling
              padding
              rightBold
              rightText
              rightTextStyling
            }
            stripped
          }
          ... on TextImageChildBlock {
            id
            type
            textImage {
              addKeyLine
              leftBold
              leftColumn
              leftText
              leftTextStyling
              padding
              rightImage {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1680) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            stripped
          }
          ... on ImageTextChildBlock {
            id
            type
            imageText {
              addKeyLine
              leftColumn
              leftImage {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1680) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              padding
              rightBold
              rightText
              rightTextStyling
            }
            stripped
          }
        }
      }
      ... on ImageBlock {
        entityType
        priority
        imageImage {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1680) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        description
        type
      }
      ... on ChartImageBlock {
        id
        svg {
          publicURL
          extension
        }
        title
        note
        insight
        entityType
        sources
        legend {
          color
          label
        }
        priority
        type
        chartId
        groupId
      }
      ... on ImageTextBlock {
        entityType
        priority
        imageImage {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1680) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        subTitle
        text
        style
      }
      ... on ImageGridBlock {
        entityType
        priority
        title
        insight
        type
        items {
          image
          subTitle
          title
          link
          imageImage {
            childImageSharp {
              fluid(quality: 80, maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      ... on InsightBlock {
        id
        entityType
        priority
        title
        items {
          title
          stat
          source
          description
          shareImage {
            publicURL
          }
        }
      }
      ... on QuoteBlock {
        id
        entityType
        priority
        company
        jobTitle
        name
        text
        extract
        shareImage {
          publicURL
        }
        headShotImage {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        parent {
          ... on soetArticle {
            id
            path
          }
        }
      }
      ... on VideoBlock {
        entityType
        title
        text
        videoUrl
      }
      ... on CarouselBlock {
        priority
        entityType
        items {
          text
          imageImage {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1680) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

// 2017 blocks that are unused
// ... on ChartImageBlock {
//   type
//   priority
//   content {
//     image
//     insight
//     title
//     note
//     sources
//     svg
//   }
// }

// 2017 blocks that were removed...
// ... on SpacerBlock {
//   type
//   priority
//   content {
//     dark
//   }
// }
// ... on VideoBlock {
//   type
//   priority
//   content {
//     id
//     url
//   }
// }

/**
 * We want to get:
 *
 * - current article
 * - info about next article for each article (used in infinite scrolling)
 */
// eslint-disable-next-line no-undef
export const articleQuery = graphql`
  query ArticleQuery($entityId: Int!) {
    soetArticle(entityId: { eq: $entityId }) {
      id
      ...ArticleFieldsFragment
    }
    allSoetArticle {
      edges {
        node {
          path
          entityId
          next {
            entityId
            path
            pathStandalone
            title
            titleNumber
            type
            illustrationImage {
              childImageSharp {
                fluid(quality: 80, maxWidth: 340) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              extension
              publicURL
            }
          }
        }
      }
    }
  }
`;
