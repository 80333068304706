import React from "react";
import styles from "./index.module.css";
import NavContext from "../NavContext";
import NavClose from "../TopBar/nav-close.svg";
import NavBurger from "../TopBar/nav-burger.svg";

const MenuBurger = ({side=false}) => {
  const { toggleReportNav, reportNavOpen } = React.useContext(NavContext);

  return (
      <button
        role="button"
        onClick={e => {
          e.preventDefault();
          toggleReportNav();
        }}
        className={styles.navToggle}
      >
        {reportNavOpen ? (
          <NavClose className={styles.icon} />
        ) : (
          <NavBurger className={styles.icon} />
        )}
      </button>
  );
};

export default MenuBurger;
