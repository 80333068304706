import React from "react";
import Introduction from "./Introduction";

const ExecutiveSummary = () => {
  return (
    <>
      <Introduction />
    </>
  );
};

export default ExecutiveSummary;
