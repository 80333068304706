import React, { useRef, useEffect, useState } from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import * as styles from "./index.module.css"

gsap.registerPlugin(ScrollToPlugin)

const SectionPagination = ({ active, amount }) => {
  const list = useRef(null)
  const [positionTop, setPositionTop] = useState(12)

  const scrollWindow = (to, duration = 1) => {
    gsap.set("body", { overflow: "hidden" })
    gsap.to(window, {
      scrollTo: { y: to, autoKill: false },
      duration: duration,
      overwrite: true,
      onComplete: () => gsap.set("body", { overflow: "auto" }),
    })
  }

  const skipToSection = where => {
    const introduction = document.querySelector(".gsap-introduction")
    const { top: introductionTop } = introduction.getBoundingClientRect()
    const introductionY = window.pageYOffset + introductionTop
    const sectionHeight = window.innerHeight
    const index = where === "next" ? active + 1 : active - 1
    const lastStep = active === 6 ? sectionHeight * 2 : sectionHeight / 2
    const to = introductionY + sectionHeight * index + sectionHeight + lastStep
    const duration = active === 6 ? 2 : 1

    scrollWindow(to, duration)
  }

  const goToSection = index => {
    const introduction = document.querySelector(".gsap-introduction")
    const { top: introductionTop } = introduction.getBoundingClientRect()
    const introductionY = window.pageYOffset + introductionTop
    const sectionHeight = window.innerHeight
    const to =
      introductionY + sectionHeight * index + sectionHeight + sectionHeight / 2

    scrollWindow(to)
  }

  useEffect(() => {
    const element = list.current
    const activeItem = element.querySelector(`.is-active`)
    const additional = 11
    const position = activeItem
      ? activeItem.offsetTop + additional
      : positionTop

    setPositionTop(position)
  }, [active, positionTop])

  const items = [...Array(amount)].map((_, i) => i)

  return (
    <div className={cn(styles.sectionPagination, "gsap-section-pagination")}>
      <button
        type="button"
        className={cn(styles.sectionPaginationPrevious)}
        onClick={() => skipToSection("previous")}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 50 50">
          <path d="M20 23.022 25 18l5 5.022M25 18v13" />
        </svg>
      </button>

      <div className={cn(styles.sectionPaginationIndicator)}>
        <div
          className={cn(styles.sectionPaginationActive)}
          style={{
            transform: `translate3d(-50%, ${positionTop}px, 0)`,
            opacity: active === -1 ? 0 : 1,
          }}
        >
          {active}
        </div>

        <div ref={list} className={cn(styles.sectionPaginationList)}>
          {items.map(i => (
            <button
              key={`section-${i}`}
              type="button"
              className={cn(
                styles.sectionPaginationButton,
                active === i ? "is-active" : ""
              )}
              onClick={() => goToSection(i)}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>

      <button
        type="button"
        className={cn(styles.sectionPaginationNext)}
        onClick={() => skipToSection("next")}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 50 50">
          <path d="M30 26.978 25 32l-5-5.023M25 32V19" />
        </svg>
      </button>
    </div>
  )
}

SectionPagination.propTypes = {
  active: PropTypes.number,
  amount: PropTypes.number,
}

SectionPagination.defaultProps = {
  active: -1,
  amount: 0,
}

export default SectionPagination
