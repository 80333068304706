import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import * as styles from "./index.module.css"

const Panel = ({ children, type, indicator }) => {
  return (
    <div
      className={cn(styles.panel, [
        type === "hero" ? styles.panelHero : styles.panelNormal,
      ])}
    >
      {indicator && (
        <div className={cn(styles.panelIndicator, "gsap-indicator")}>
          <img
            className={styles.panelIndicatorAsset}
            src={"../../images/mini-wheel.svg"}
            alt=""
          />
        </div>
      )}

      <div className={cn(styles.panelContent, "gsap-content")}>
        <div className={styles.panelInner}>{children}</div>
      </div>
    </div>
  )
}

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  type: PropTypes.oneOf(["hero", "normal"]),
  indicator: PropTypes.bool,
}

Panel.defaultProps = {
  children: null,
  type: `normal`,
  indicator: true,
}

export default Panel
