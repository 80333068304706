import React from "react"
import cn from "classnames"
import HighlightTitle from "../HighlightTitle/index.js"
import * as styles from "./index.module.css"

const FlyWheel = () => {
  const text = "The European Tech Flywheel"
  const factor = 1.5
  let accumulator = 0
  const fan = text
    .split("")
    .map((item, index) => {
      const amount = (factor + accumulator) * index

      if (["w"].includes(item)) {
        accumulator = accumulator + 0.02
      }

      return `<span style="transform: rotate(${amount}deg);">${item}</span>`
    })
    .join("")

  return (
    <>
      <div className={styles.flywheel}>
        <div className={styles.flywheelClip} />
        <div className={styles.flywheelRing} />

        <p
          className={cn(styles.flywheelText, styles.flywheelTextLeft)}
          dangerouslySetInnerHTML={{ __html: fan }}
        />
        <p
          className={cn(styles.flywheelText, styles.flywheelTextRight)}
          dangerouslySetInnerHTML={{ __html: fan }}
        />

        <div className={styles.flywheelTitles}>
          <div className={cn(styles.flywheelTitle, "gsap-flywheel-title")}>
            <HighlightTitle>
              As the European tech sector grows,{" "}
              <strong>the flywheel is spinning faster</strong>
            </HighlightTitle>
          </div>

          <div className={cn(styles.flywheelTitle, "gsap-flywheel-title")}>
            <HighlightTitle>
              As the tech industry grows, so does{" "}
              <strong>the talent pool</strong>
            </HighlightTitle>
          </div>

          <div className={cn(styles.flywheelTitle, "gsap-flywheel-title")}>
            <HighlightTitle>
              A strong talent pool leads to{" "}
              <strong>better ideas and better companies</strong>
            </HighlightTitle>
          </div>

          <div className={cn(styles.flywheelTitle, "gsap-flywheel-title")}>
            <HighlightTitle>
              Better ideas and companies attract more{" "}
              <strong>world class investors...</strong>
            </HighlightTitle>
          </div>

          <div className={cn(styles.flywheelTitle, "gsap-flywheel-title")}>
            <HighlightTitle>
              The best investors support{" "}
              <strong>the strongest outcomes...</strong>
            </HighlightTitle>
          </div>
          <div className={cn(styles.flywheelTitle, "gsap-flywheel-title")}>
            <HighlightTitle>
              These outcomes in turn catalyse{" "}
              <strong>more investment, talent, and ideas...</strong>
            </HighlightTitle>
          </div>
        </div>

        <div className={cn(styles.flywheelPosition, "gsap-plan-position")}>
          <div className={cn(styles.flywheelPositionInner)}>
            <div className={cn(styles.flywheelWheels)}>
              <div className={cn(styles.flywheelWheel, styles.flywheelWheel1)}>
                <img
                  className={cn(
                    styles.flywheelWheelImage,
                    "gsap-flywheel-image"
                  )}
                  src={"../../images/mini-wheel-start.svg"}
                  alt=""
                />
              </div>
              <div className={cn(styles.flywheelWheel, styles.flywheelWheel2)}>
                <img
                  className={cn(
                    styles.flywheelWheelImage,
                    "gsap-flywheel-image"
                  )}
                  src={"../../images/mini-wheel.svg"}
                  alt=""
                />
              </div>
              <div className={cn(styles.flywheelWheel, styles.flywheelWheel3)}>
                <img
                  className={cn(
                    styles.flywheelWheelImage,
                    "gsap-flywheel-image"
                  )}
                  src={"../../images/mini-wheel.svg"}
                  alt=""
                />
              </div>
              <div className={cn(styles.flywheelWheel, styles.flywheelWheel4)}>
                <img
                  className={cn(
                    styles.flywheelWheelImage,
                    "gsap-flywheel-image"
                  )}
                  src={"../../images/mini-wheel.svg"}
                  alt=""
                />
              </div>
              <div className={cn(styles.flywheelWheel, styles.flywheelWheel5)}>
                <img
                  className={cn(
                    styles.flywheelWheelImage,
                    "gsap-flywheel-image"
                  )}
                  src={"../../images/mini-wheel.svg"}
                  alt=""
                />
              </div>
              <div className={cn(styles.flywheelWheel, styles.flywheelWheel6)}>
                <img
                  className={cn(
                    styles.flywheelWheelImage,
                    "gsap-flywheel-image"
                  )}
                  src={"../../images/mini-wheel-end.svg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FlyWheel
