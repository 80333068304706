// Actions
const typePrefix = `chart/`;
const SET = `${typePrefix}SET`;
const CLEAR = `${typePrefix}CLEAR`;

// Selectors

export const getChart = state => state.chart;

// Action creators

/**
 *
 * @param {string} chartId the chart to be toggled
 * @return {function} thunk action creator
 */
export const setChart = ({
  chartId,
  groupId,
  chartRef,
  chartTitleNumber,
  disabledDataDownload
}) => ({
  type: SET,
  chartId,
  groupId,
  chartRef,
  chartTitleNumber,
  disabledDataDownload
});

export const clearChart = () => ({
  type: CLEAR
});

const emptyState = {
  chartId: null,
  groupId: false,
  chartRef: null,
  chartTitleNumber: null,
  disabledDataDownload: false
};

// Reducer
export function chartReducer(state = emptyState, action = {}) {
  switch (action.type) {
    case SET:
      return {
        chartId: action.chartId,
        groupId: action.groupId,
        chartRef: action.chartRef,
        chartTitleNumber: action.chartTitleNumber,
        disabledDataDownload: action.disabledDataDownload
      };
    case CLEAR:
      return { ...emptyState };
    default:
      return state;
  }
}
