import React from "react";
import ScrollArrow from "../ScrollArrow";
import Grid, { Full, Half, Thin, Wide } from "../Grid";
import styles from "./index.module.css";
import { ShareLinkedIn, ShareTwitter } from "../Share";
import PartnerLogo from "../PartnerLogo";
import { graphql, useStaticQuery } from "gatsby";

const Insight = ({
  number,
  path,
  title,
  titleNumber,
  text,
  source,
  includeLink,
  whiteSource,
  idx
}) => {
  const siteData = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl: url
        }
      }
    }
  `);

  let shareUrl = `${siteData.site.siteMetadata.siteUrl}${path}`
  if(idx !== null){
    shareUrl = `${shareUrl}insight/${idx}`
  }
  return (
    <React.Fragment>
      <div data-number={number || ``} className={styles.title}>
        {titleNumber} {title}
      </div>
      <div className={styles.text}>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </div>
      <div className={styles.scrollWrapper}>
        {path && includeLink && (
          <ScrollArrow className={styles.scrollArrow} to={path} title={title} />
        )}
      </div>
      <div className={styles.footer}>
        <div className={styles.source}>
          {source && (
            <>
              <span className={styles.sourceLabel}>Source</span>
              <div className={styles.vline}></div>
              <PartnerLogo
                className={styles.sourceLogo}
                partner={source}
                white={whiteSource}
              />
            </>
          )}
        </div>
        <div className={styles.share}>
          <ShareTwitter
            className={styles.link}
            text={`${title} - ${text.replace(`<p>`, ``).replace(`</p>`, ``)}`}
            url={shareUrl}
            large={true}
            alternate={false}
            inverted={true}
          />
          <ShareLinkedIn
            className={styles.link}
            title={title}
            text={`${title} - ${text.replace(`<p>`, ``).replace(`</p>`, ``)}`}
            url={shareUrl}
            large={true}
            alternate={false}
            inverted={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const HeaderInsights = ({
  title,
  text,
  insights,
  insightsSpecificShare = false,
  children,
  includeLink,
  className,
  whiteSources,
  heading = `Articles`
}) => {
  return (
    <>
      <a id={`insights`}></a>
      <Grid className={`${className || styles.insights}`}>
        <Half className={styles.firstParagraph}>
          <div className={styles.firstParagraphHeading}>{title}</div>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </Half>
        <Full>
          <hr className="separator" />
        </Full>
        <Thin className="heading">{heading}</Thin>
        {/* Loop to form insights */}
        {insights.map((i, idx) => (
          <React.Fragment key={i.id}>
            <Insight
              title={i.title}
              text={i.text}
              path={i.path}
              source={i.source}
              titleNumber={i.titleNumber}
              includeLink={includeLink === undefined ? true : includeLink}
              whiteSource={whiteSources === undefined ? true : whiteSources}
              idx={insightsSpecificShare ? idx : null}
            />
            {idx < insights.length - 1 && (
              <Wide className={styles.insightSeparator}>
                <hr className="separator" />
              </Wide>
            )}
          </React.Fragment>
        ))}
        {children}
      </Grid>
    </>
  );
};

export default HeaderInsights;
