import React from "react";
import PropTypes from "prop-types";

import Modal from "../Modal";
import DownloadFormContainer from "../DownloadForm/container";
import DownloadSuccessContainer from "../DownloadSuccess/container";
import DownloadErrorContainer from "../DownloadError/container";
import DownloadChartAgreedContainer from "../DownloadChartAgreed/container";
import SurveyModalContainer from "../SurveyModal/index";

import { modals } from "../../state/modal";
import styles from "../Modal/index.module.css";

const modalComponents = {
  [modals.DOWNLOAD_REPORT]: DownloadFormContainer,
  [modals.DOWNLOAD_CHART]: DownloadFormContainer,
  [modals.DOWNLOAD_IMAGE]: DownloadFormContainer,
  [modals.DOWNLOAD_CHART_AGREED]: DownloadChartAgreedContainer,
  [modals.DOWNLOAD_SUCCESS]: DownloadSuccessContainer,
  [modals.DOWNLOAD_ERROR]: DownloadErrorContainer,
  [modals.SURVEY]: SurveyModalContainer,
};

const ModalRoot = ({ modal, onClose }) => {
  const { isOpen, modalType, modalProps } = modal;
  const ModalInner = modalComponents[modalType] || `div`;
  const className = modalType === modals.SURVEY ? styles.alt : undefined;

  return (
    <Modal
      onRequestClose={onClose}
      isOpen={isOpen}
      modalAppElement="#___gatsby"
      className={className}
    >
      <ModalInner {...modalProps} />
    </Modal>
  );
};

ModalRoot.propTypes = {
  modal: PropTypes.shape({
    isOpen: PropTypes.bool,
    modalType: PropTypes.symbol, // can't check for specific Symbols due to https://github.com/facebook/prop-types/issues/10
    modalProps: PropTypes.shape({}) // can be anything
  }),
  onClose: PropTypes.func
};

export default ModalRoot;
