import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import { ShareLinkedIn, ShareTwitter } from "../Share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Svg from "../Svg";
import copylink from "../ArticleFooterShare/copylink.svg";
import Grid, { Half } from "../Grid";
import Illustration from "../Illustration";
import HeaderInsights from "../HeaderInsights";
import { graphql, StaticQuery } from "gatsby";

const ArticleHeaderWithoutStaticQuery = ({
  title,
  titleNumber,
  entityId,
  siteUrl,
  path,
  illustrationImage,
  insights,
  insightSummaryTitle,
  insightSummaryText
}) => {
  const shareUrl = `${siteUrl}${path}`;
  const [copied, setCopied] = React.useState(false);

  const clearState = () => {
    setCopied(false);
  };

  const ResponsiveIllustration = ({ className }) => {
    return (
      <Illustration
        className={className}
        illustrationImage={illustrationImage}
        title={title}
      />
    );
  };

  return (
    <>
      <ResponsiveIllustration className={styles.illustrationTop} />
      <Grid className={styles.root}>
        <Half>
          <div className={styles.titleWrapper}>
            <div>
              <h1 className={styles.titleNumber}>{titleNumber}</h1>
              <h1 className={styles.title}>{title}</h1>
            </div>
            <div className={styles.headerActions}>
              <div className={styles.shareIcon}>
                <div
                  className={
                    styles.shareNotification +
                    (copied ? ` ` + styles.show : ` ` + styles.hide)
                  }
                  onClick={clearState}
                >
                  <p className={styles.shareNotificationText}>Link copied!</p>
                </div>
                <CopyToClipboard text={shareUrl} onCopy={() => setCopied(true)}>
                  <a className={styles.rootCopy}>
                    <Svg className={styles.rootCopyIcon} svg={copylink} />
                  </a>
                </CopyToClipboard>
              </div>
              <div className={styles.shareIcon}>
                <ShareTwitter
                  text={title}
                  url={shareUrl}
                  large={false}
                  alternate={true}
                />
              </div>
              <div className={styles.shareIcon}>
                <ShareLinkedIn
                  url={`${siteUrl}`}
                  sourceUrl={shareUrl}
                  title={title}
                  large={false}
                  alternate={true}
                />
              </div>
            </div>
          </div>
        </Half>
        <Half>
          <ResponsiveIllustration className={styles.illustration} />
        </Half>
      </Grid>
      {insights.length > 0 ? (
        <HeaderInsights
          className={styles.headerInsights}
          title={insightSummaryTitle}
          text={insightSummaryText}
          insights={insights.map(i => ({ ...i, path }))}
          insightsSpecificShare={true}
          includeLink={false}
          whiteSources={false}
          heading={`Insights`}
        />
      ) : (
        ``
      )}
    </>
  );
};

// TODO: run this query directly from the Share component instead of here?
export default props => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            siteUrl: url
          }
        }
      }
    `}
    render={data => (
      <ArticleHeaderWithoutStaticQuery
        siteUrl={data.site.siteMetadata.siteUrl}
        {...props}
      />
    )}
  />
);

ArticleHeaderWithoutStaticQuery.propTypes = {
  titleNumber: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  entityId: PropTypes.number,
  siteUrl: PropTypes.string,
  path: PropTypes.string,
  illustrationImage: PropTypes.shape({
    fluid: PropTypes.shape({}),
    extension: PropTypes.string,
    publicURL: PropTypes.string
  }),
  insights: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      path: PropTypes.string,
      source: PropTypes.string
    })
  ),
  insightSummaryTitle: PropTypes.string,
  insightSummaryText: PropTypes.string
};
