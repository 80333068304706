import React, { Component } from "react";

import Grid, { Full } from "../Grid";
import PropTypes from "prop-types";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import Svg from "../Svg";
import leftArrow from "./leftArrow.svg";
import rightArrow from "./rightArrow.svg";

import styles from "./index.module.css";
import Img from "gatsby-image";

const createCarouselItem = (index, item) => (
  <div className={styles.carouselItem} key={index}>
    <div className={styles.carouselImage}>
      <Img
        fluid={{ ...item.imageImage.childImageSharp.fluid, aspectRatio: 1 }}
        style={{ paddingBottom: 0 }}
      />
    </div>
    <div className={styles.carouselText}>{item.text}</div>
  </div>
);

class ExternalControlledCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0
    };
  }

  next = () => {
    this.setState(state => ({
      currentSlide: state.currentSlide + 1
    }));
  };

  prev = () => {
    this.setState(state => ({
      currentSlide: state.currentSlide - 1
    }));
  };

  updateCurrentSlide = index => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
      this.setState({
        currentSlide: index
      });
    }
  };

  render() {
    const zeroPad = (num, places) => String(num).padStart(places, `0`);

    return (
      <>
        <Carousel
          selectedItem={this.state.currentSlide}
          onChange={this.updateCurrentSlide}
          {...this.props}
        ></Carousel>
        <div className={styles.navigationContainer}>
          <div className={styles.navigationStatus}>
            {zeroPad(this.state.currentSlide + 1, 2)}
            <span className={styles.navigationStatusGrey}>
              {` `}/ {zeroPad(this.props.children.length, 2)}
            </span>
          </div>
          <div>
            <button onClick={this.prev} className={styles.navigationButton}>
              <Svg svg={leftArrow} cleanup={[`fill`]} />
            </button>
            <button onClick={this.next} className={styles.navigationButton}>
              <Svg svg={rightArrow} cleanup={[`fill`]} />
            </button>
          </div>
        </div>
      </>
    );
  }
}

const BlockCarousel = ({ items }) => {
  return (
    <Grid className={styles.root}>
      <Full className="carousel-wrapper">
        <ExternalControlledCarousel
          showArrows={false}
          transitionTime={0}
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          emulateTouch={false}
          swipeable={false}
        >
          {items.map((item, i) => createCarouselItem(i, item))}
        </ExternalControlledCarousel>
      </Full>
    </Grid>
  );
};

BlockCarousel.propTypes = {
  id: PropTypes.string
};

export default BlockCarousel;
