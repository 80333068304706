import { graphql, Link, StaticQuery } from "gatsby";
import styles from "./index.module.css";
import React from "react";

const ScrollArrow = ({ to, title, className }) => (
  <StaticQuery
    query={graphql`
      {
        nextChapterImage: file(relativePath: { eq: "next-chapter.png" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 120) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
        }
      }
    `}
    render={data => (
      <Link
        className={`${className ? className : ``} ${styles.scrollArrow}`}
        to={to}
      >
        <img
          className={styles.icon}
          src={data.nextChapterImage.childImageSharp.fluid.srcWebp}
          alt={title}
        />
      </Link>
    )}
  />
);

export default ScrollArrow;
