import React from "react";
import { connect } from "react-redux";
import Icon from "./icon.svg";
import { requestReportDownload } from "../../state/download/report";
import styles from "./index.module.css";

const DownloadButton = ({ className, onDownloadClick }) => {
  return (
    <button
      type="button"
      onClick={onDownloadClick}
      className={className || styles.downloadButton}
    >
      <Icon className={styles.icon} />
      <span>Download Report</span>
    </button>
  );
};

const withRedux = connect(
  () => ({}),
  dispatch => ({
    onDownloadClick(e) {
      e.preventDefault();
      dispatch(requestReportDownload());
    }
  })
);

export default withRedux(DownloadButton);
