import React from "react";
import { graphql, StaticQuery } from "gatsby";

import TogglePanel from "./TogglePanel";
import NavContent from "./NavContent";

const ReportNav = props => (
  <StaticQuery
    query={graphql`
      {
        allSoetChapter(sort: { fields: [sortOrder], order: ASC }) {
          nodes {
            id
            entityId
            path
            subTitle
            title
            titleNumber
            soetArticle {
              id
              entityId
              path
              title
              titleNumber
              illustrationImage {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 340) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
                extension
                publicURL
              }
            }
          }
        }
        allSoetArticle {
          nodes {
            entityId
          }
        }
      }
    `}
    render={data => (
      <>
        <NavContent />
        <TogglePanel />
      </>
    )}
  />
);

export default ReportNav;
