import React from "react";
// import PropTypes from "prop-types";
import { connectSearchBox } from "react-instantsearch-dom";
import debounce from "lodash/debounce";
import SearchIcon from "./searchIcon.svg";

import analytics from "../../utils/analytics";

import styles from "./index.module.css";
import NavContext from "../NavContext";

// const CloseContainer = connectStateResults(
//   ({ searchState, searchResults, searching, hitComponent }) => {
//     const hasResults = !!searchResults && searchResults.nbHits !== 0;
//     const hasQuery = !!searchState && !!searchState.query;

//     return (
//       <React.Fragment>
//         {hasResults && hasQuery && <div className={styles.clickArea}></div>}
//       </React.Fragment>
//     );
//   }
// );

const SearchInput = connectSearchBox(({ refine }) => {
  const debouncedSearch = debounce(e => refine(e.target.value), 300);
  const debouncedAnalytics = debounce(analytics, 300);
  const { setCurrentSearch, currentSearch } = React.useContext(NavContext);
  const onChange = e => {
    e.persist();
    setCurrentSearch(e.target.value);
    debouncedSearch(e, e.eventTarget);
    debouncedAnalytics(`event`, `search`, {
      event_category: `search`,
      event_label: `searchTerm`,
      event_value: e.target.value
    });
  };
  // const onClickClear = ev => {
  //   refine(``);
  //   ev.stopPropagation();
  //   ev.currentTarget.classList.add(`isClosed`);
  // };

  React.useEffect(() => {
    if (currentSearch) {
      refine(currentSearch);
    }
  });

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <label className={styles.label}>
          <input
            className={styles.input}
            placeholder="Search the report"
            type="search"
            onChange={onChange}
            value={currentSearch}
          />
          <SearchIcon className={styles.icon} />
        </label>
      </div>
    </div>
  );
});

export default SearchInput;
