import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import * as styles from "./index.module.css"

const PanelSection = ({ children, active }) => {
  const [classy, setClassy] = useState(active)

  useEffect(() => {
    setClassy(active ? styles.panelSectionActive : "")
  }, [active])

  return (
    <section className={cn(styles.panelSection, classy, "gsap-panel-section")}>
      {children}
    </section>
  )
}

PanelSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  active: PropTypes.bool,
}

PanelSection.defaultProps = {
  children: null,
  active: false,
}

export default PanelSection
