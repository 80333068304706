import React from "react";
import { NavTabsContextProvider } from "./context";
import TabButtons from "./TabsButtons";
import TabContent from "./TabContent";
import styles from "./index.module.css";
import NavContext from "../../NavContext";
import { Helmet } from "react-helmet/es/Helmet";

const NavContent = () => {
  const { reportNavOpen } = React.useContext(NavContext);

  return (
    <div
      className={`${styles.navContent} ${reportNavOpen ? styles.isOpen : ``}`}
    >
      <Helmet
        bodyAttributes={{
          style: `overflow: ${reportNavOpen ? `hidden` : `auto`}`
        }}
      />
      <NavTabsContextProvider>
        <TabButtons />
        <TabContent />
      </NavTabsContextProvider>
    </div>
  );
};

export default NavContent;
