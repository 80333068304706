import { Highlight, Snippet } from "react-instantsearch-dom";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import TextTruncate from "react-text-truncate";

import styles from "./index.module.css";
import Link from "gatsby-link";
import NavContext from "../../NavContext";

const HIT_MATCH = /^(full|partial)$/;

// const removeTrailingSlashes = function(url) {
//   return url.replace(/\/+$/, ``);
// };

const matchHitSnippet = (hit, attribute) => {
  const res = get(hit, `_snippetResult.${attribute}.matchLevel`, ``).match(
    HIT_MATCH
  );
  return res;
};

const MaybeSnippet = ({ hit, attribute }) => {
  if (!matchHitSnippet(hit, attribute)) return null;
  return (
    <p className={styles.snippet}>
      <Snippet attribute={attribute} hit={hit} />
    </p>
  );
};

MaybeSnippet.propTypes = {
  hit: PropTypes.shape({}),
  attribute: PropTypes.string
};

const MaybeHighlightGroup = ({ hit, attribute, className }) => {
  if (hit.group.name === `Default`) return null;
  return (
    <span className={styles.smallText}>
      Dataset:
      <Highlight
        attribute="group.name"
        hit={hit}
        className={styles.groupNameHighlight}
      />
    </span>
  );
};

MaybeHighlightGroup.propTypes = {
  hit: PropTypes.shape({}),
  attribute: PropTypes.string,
  className: PropTypes.string
};

const Hit = ({ hit }) => {
  const { toggleSearchBar } = React.useContext(NavContext);
  if (hit.article === true) {
    const titleMatch = get(hit, `_highlightResult.title.matchLevel`).match(
      HIT_MATCH
    );
    const anchorId = titleMatch
      ? `#article-${hit.entityId}`
      : `#block-${hit.entityId}-${hit.block.priority}`;
    const path = hit.path;

    return (
      <Link
        className={styles.root}
        to={path + `${anchorId}`}
        onClick={e => {
          toggleSearchBar();
        }}
      >
        <div className={styles.searchFacetArticle}>Article</div>
        <div className={styles.titleWrapper}>
          <Highlight
            attribute="title"
            hit={hit}
            className={styles.titleArticle}
          />
        </div>
        <div className={styles.bodyText}>
          <TextTruncate
            element="p"
            line={3}
            text={hit.block.textStripped || hit.block.title}
          />
        </div>
        <MaybeSnippet attribute="block.text" hit={hit} />
        <MaybeSnippet attribute="block.title" hit={hit} />
        <MaybeSnippet attribute="block.subTitle" hit={hit} />
        <p className={styles.titleNumber}>
          <span>{hit.titleNumber}</span> - {hit.soetChapter.title}
        </p>
      </Link>
    );
  } else {
    const anchorId = `#chart-${hit.chartId}-${hit.group.id}`;
    // const chartPath = hit.path + `#chart-` + hit.chartId;
    return (
      <Link
        className={styles.root}
        to={hit.path + `${anchorId}`}
        onClick={e => {
          toggleSearchBar();
        }}
      >
        <div className={styles.searchFacetChart}>Chart</div>
        <Highlight attribute="title" hit={hit} className={styles.title} />
        <MaybeHighlightGroup
          attribute="group.name"
          hit={hit}
          className={styles.groupName}
        />
        <MaybeSnippet attribute="insight" hit={hit} />
        <MaybeSnippet attribute="insight.title" hit={hit} />
        <p className={styles.titleNumber}>
          <span>{hit.titleNumber}</span> - {hit.chartTitle}
        </p>
      </Link>
    );
  }
};

Hit.propTypes = {
  hit: PropTypes.shape({}),
  siteUrl: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default Hit;
