import React from "react";
import NavContext from "../../src/components/NavContext";
import { isMobile } from "react-device-detect";
// import rafThrottle from "../../utils/rafThrottle";
const OBSERVER_SELECTOR = `#report-content [data-observe-position]`;

class Provider extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }
  state = {
    activePathname: null,
    setActivePathname: activePathname => {
      this.setState({ activePathname: activePathname.split(`#`)[0] });
    },
    reportNavOpen: false,
    searchBarOpen: false,
    currentSearch: null,
    setCurrentSearch: currentSearch => {
      this.setState({ currentSearch: currentSearch });
    },
    mobileDevice: false,
    toggleReportNav: () => {
      this.setState({ reportNavOpen: !this.state.reportNavOpen });
    },
    toggleSearchBar: () => {
      this.setState({ searchBarOpen: !this.state.searchBarOpen });
    },
    closeReportNav: () => {
      this.setState({ reportNavOpen: false });
    },
    showIsMobile: () => {
      if (isMobile) {
        this.setState({ mobileDevice: true });
      }
    }
  };

  componentDidMount() {
    // add this callback to window so our wrapped pushstate can trigger it (see gatsby-browser.js)
    if (window && !window.___setActivePathname) {
      window.___setActivePathname = this.state.setActivePathname;
      this.state.setActivePathname(window.location.pathname);
    }

    window.addEventListener(`scroll`, this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener(`scroll`, this.handleScroll);
  }

  handleScroll(event) {
    if (window.scrollY === 0) return;
    const elements = document.querySelectorAll(OBSERVER_SELECTOR);
    if (elements.length < 2) return;
    const activePathname = this.state.activePathname;

    // find the element closest to the active threshold
    const activeThreshold = window.innerHeight / 2;
    const active = Array.from(elements)
      .filter(el => el.getBoundingClientRect().top < activeThreshold)
      .pop();

    const nextActivePathname = active
      ? active.attributes[`data-pathname`].value
      : undefined;
    if (
      nextActivePathname !== undefined &&
      nextActivePathname !== activePathname
    ) {
      // update the current url, bypassing Gatsby's normal link handling as
      // we've manually preloaded resources already in InfiniteArticles
      window.history.pushState({}, ``, nextActivePathname);
    }

    // requestIdleCallback(() => {
    //   this.props.setActiveId(activeId);
    // });
    // }
  }

  render() {
    return (
      <NavContext.Provider value={this.state}>
        {this.props.children}
      </NavContext.Provider>
    );
  }
}

// eslint-disable-next-line react/prop-types
export default ({ element }) => <Provider>{element}</Provider>;
