import React from "react";
import styles from "./index.module.css";
import PrimaryButton from "../PrimaryButton";
import {setSurveyNoticeComplete} from "../../state/surveyNoticeComplete";
import {connect} from "react-redux";
import {getCookieNotice, setCookieNotice} from "../../state/cookieNotice";
import CookieMessage from "../CookieMessage";

const SurveyModal = ({dismissSurveyNoticeComplete}) => {
  return <div className={styles.root}>
    <h2 className={styles.title}>Sign up for the 2022 State of European Tech virtual launch event!</h2>
    <div className={styles.content}>
      <p>
        We’ve listened to the ecosystem.
        We’ve harnessed the data.
        We’ve uncovered the real story of European Tech in 2022.
        All that’s left to do is share this story with you.
      </p>
      <p>
        Join the State of European Tech livestream on Wednesday, 7th December at 2:30pm (GMT)
      </p>
      <PrimaryButton arrowThin={true} dark={true} onClick={e => {
          e.preventDefault();
          dismissSurveyNoticeComplete();
          window.location.assign("https://events.atomico.com/soet2022livestream");
        }}>
        RSVP
      </PrimaryButton>
    </div>
  </div>
};

const withRedux = connect(
  state => ({
    acceptedCookieNotice: getCookieNotice(state)
  }),
  dispatch => ({
    dismissSurveyNoticeComplete() {
      dispatch(setSurveyNoticeComplete());
    }
  })
);

export default withRedux(SurveyModal);
