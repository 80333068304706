import React from "react";
import Grid from "../../../Grid";
import styles from "./index.module.css";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Icon from "./icon.svg";

const PreviousReports = () => {
  const { img2017, img2018, img2019, img2020 } = useStaticQuery(graphql`
    {
      img2017: file(base: { eq: "2017-report.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2018: file(base: { eq: "2018-report.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2019: file(base: { eq: "2019-report.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2020: file(base: { eq: "2020-report.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Grid className={styles.previousReportGrid}>
      <h7 className={styles.previousReportHeading}>
        All Previous State of European Tech Reports
      </h7>
      <a
        className={styles.previousReportLink}
        target="_blank"
        href="https://2017.stateofeuropeantech.com/"
        rel="noreferrer"
      >
        <Img
          className={styles.previousReportImage}
          fluid={img2017.childImageSharp.fluid}
          alt="2017"
        />
        <span className={styles.previousReportLinkText}>
          The State of European Tech 2017
        </span>
        <div className={styles.previousReportLinkArrow}>
          <Icon className={styles.icon} />
        </div>
      </a>
      <a
        className={styles.previousReportLink}
        target="_blank"
        href="https://2018.stateofeuropeantech.com/"
        rel="noreferrer"
      >
        <Img
          className={styles.previousReportImage}
          fluid={img2018.childImageSharp.fluid}
          alt="2018"
        />
        <span className={styles.previousReportLinkText}>
          The State of European Tech 2018
        </span>
        <div className={styles.previousReportLinkArrow}>
          <Icon className={styles.icon} />
        </div>
      </a>
      <a
        className={styles.previousReportLink}
        target="_blank"
        href="https://2019.stateofeuropeantech.com/"
        rel="noreferrer"
      >
        <Img
          className={styles.previousReportImage}
          fluid={img2019.childImageSharp.fluid}
          alt="2019"
        />
        <span className={styles.previousReportLinkText}>
          The State of European Tech 2019
        </span>
        <div className={styles.previousReportLinkArrow}>
          <Icon className={styles.icon} />
        </div>
      </a>
      <a
        className={styles.previousReportLink}
        target="_blank"
        href="https://2020.stateofeuropeantech.com/"
        rel="noreferrer"
      >
        <Img
          className={styles.previousReportImage}
          fluid={img2020.childImageSharp.fluid}
          alt="2020"
        />
        <span className={styles.previousReportLinkText}>
          The State of European Tech 2020
        </span>
        <div className={styles.previousReportLinkArrow}>
          <Icon className={styles.icon} />
        </div>
      </a>
    </Grid>
  );
};

export default PreviousReports;
