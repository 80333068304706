import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import * as styles from "./index.module.css"

const VimeoIframe = ({ src, title, description }) => {
  return (
    <div className={cn(styles.vimeoIframe)}>
      {src && src.length && title && title.length && (
        <div className={cn(styles.vimeoIframePlayer)}>
          <iframe
            title={title}
            src={src}
            width="640"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </div>
      )}

      {description && description.length && (
        <p className={cn(styles.vimeoIframeDescription)}>{description}</p>
      )}
    </div>
  )
}

VimeoIframe.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
}

VimeoIframe.defaultProps = {
  src: "",
  title: "",
  description: "",
}

export default VimeoIframe
