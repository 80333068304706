/**
 * Composes all top-level reducers.
 */

import { combineReducers } from "redux";

import { articlesReducer } from "./articles";
import { chartReducer } from "./chart";
import { modalReducer } from "./modal";
import { userReducer } from "./user";
import { cookieNoticeReducer } from "./cookieNotice";
import { surveyNoticeCompleteReducer } from "./surveyNoticeComplete";
import { imageReducer } from "./image";
// import { navSectionReducer } from "./navSection";

export const rootReducer = combineReducers({
  articles: articlesReducer,
  chart: chartReducer,
  modal: modalReducer,
  user: userReducer,
  image: imageReducer,
  cookieNotice: cookieNoticeReducer,
  surveyNoticeComplete: surveyNoticeCompleteReducer
  // navSection: navSectionReducer,
});
