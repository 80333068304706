import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as styles from "./index.module.css"

const LinkButton = ({ children, ...props }) => {
  return (
    <Link {...props} className={styles.linkButton}>
      {children}
      <span className={styles.linkArrow}>
        <svg
          width="14"
          height="10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 5.242h11.884m0 0-3.961-4m3.96 4-3.96 4"
            stroke="#113A46"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </Link>
  )
}

LinkButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

LinkButton.defaultProps = {
  children: null,
}

export default LinkButton
