import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import BgQuoteLeftIcon from "./bgQuoteLeft.svg";
import Img from "gatsby-image";
import { ShareLinkedIn, ShareTwitter } from "../Share";
import Grid, { Half, Thin } from "../Grid";
import { requestImageDownload } from "../../state/download/share-image";
import { connect } from "react-redux";
import ActionButton from "../ActionButton";
import { graphql, StaticQuery } from "gatsby";

class QuoteSocialWithoutStatic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ``,
      copied: false
    };
  }

  render() {
    const {
      title,
      siteUrl,
      path,
      id,
      shareImage,
      onDownloadClicked
    } = this.props;
    const linkedinText = title;
    const twitterText = title;
    const shareUrl = `${siteUrl}${path}${id}#q${id}`;
    return (
      <div className={styles.social}>
        <div className={styles.socialAction}>
          <ActionButton
            onClick={e => {
              e.preventDefault();
              onDownloadClicked(shareImage.publicURL);
            }}
          />
        </div>
        <div className={styles.socialAction}>
          <ShareLinkedIn
            url={shareUrl}
            sourceUrl={shareUrl}
            title={linkedinText}
          />
        </div>
        <div className={styles.socialAction}>
          <ShareTwitter text={twitterText} url={shareUrl} />
        </div>
      </div>
    );
  }
}

class QuoteSocial extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            site {
              siteMetadata {
                siteUrl: url
              }
            }
          }
        `}
        render={data => (
          <QuoteSocialWithoutStatic
            siteUrl={data.site.siteMetadata.siteUrl}
            {...this.props}
          />
        )}
      />
    );
  }
}

const ReduxConnectedBlockQuote = ({
  id,
  text,
  name,
  jobTitle,
  company,
  extract,
  headShotImage,
  parent,
  shareImage,
  onDownloadClicked
}) => {
  let quoteHeadshotGrid = styles.quoteHeadshotGrid;
  if (!text) {
    quoteHeadshotGrid = styles.quoteHeadshotGridOffset;
  }

  return (
    <Grid className={styles.root}>
      <a id={`q${id}`} />
      <Thin className={quoteHeadshotGrid}>
        <div className={styles.quoteHeadshot}>
          <BgQuoteLeftIcon className={styles.quoteIcon} />
          {headShotImage && headShotImage.childImageSharp && (
            <Img
              fixed={headShotImage.childImageSharp.fluid}
              className={styles.headShot}
            />
          )}
        </div>
      </Thin>
      <Half>
        <blockquote>
          <cite className={styles.cite}>
            <header className={styles.quoteExtract}>
              <p>{extract}</p>
            </header>

            {text && (
              <div
                className={styles.mainQuoteText}
                dangerouslySetInnerHTML={{ __html: text }}
              ></div>
            )}

            <footer className={styles.citeFooter}>
              <div className={styles.author}>
                <p className={styles.name}>{name}</p>
                {company && (
                  <p className={styles.company}>
                    {company} {jobTitle && ` | ` + jobTitle}
                  </p>
                )}
              </div>
              <QuoteSocial
                onDownloadClicked={onDownloadClicked}
                shareImage={shareImage}
                title={extract}
                path={parent.path}
                id={id}
              />
            </footer>
          </cite>
        </blockquote>
      </Half>
    </Grid>
  );
};

const withRedux = connect(
  () => ({}),
  dispatch => ({
    onDownloadClicked(url) {
      dispatch(
        requestImageDownload({
          url: url,
          filename: url.split(`/`).pop()
        })
      );
    }
  })
);

const BlockQuote = withRedux(ReduxConnectedBlockQuote);

BlockQuote.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string,
  extract: PropTypes.string,
  jobTitle: PropTypes.string,
  company: PropTypes.string,
  headShot: PropTypes.string,
  headShotImage: PropTypes.shape({}),
  shareImage: PropTypes.shape({})
};

export default BlockQuote;
