import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import Img from "gatsby-image";
import Grid, { Full } from "../Grid";

const BlockImageText = ({
  title,
  skipTitle,
  subTitle,
  text,
  addKeyLine,
  description,
  imageImage,
  style
}) => {
  const rootStyle = addKeyLine
    ? styles.rootWithKeyline
    : styles.root && style == `image_top`
    ? styles.rootImageOnTop
    : styles.root && style == `image_right`
    ? styles.rootImageOnRight
    : styles.root && style == `image_left`
    ? styles.rootImageOnLeft
    : styles.root;

  const contentColsStyle =
    style == `image_top`
      ? styles.contentColsImageTop
      : styles.contentCols && style == `image_right`
      ? styles.contentColsImageRight
      : styles.contentCols && style == `image_left`
      ? styles.contentColsImageLeft
      : styles.contentCols;

  const imageStyle =
    style == `image_top`
      ? styles.rootImageTop
      : styles.image && style == `image_right`
      ? styles.rootImageRight
      : styles.image && style == `image_left`
      ? styles.rootImageLeft
      : styles.image;

  // set base styles here
  // TODO: can we make this better?

  return (
    <Grid className={rootStyle}>
      <Full>
        {style == `image_top` && (
          <Img
            className={imageStyle}
            alt={description}
            fluid={imageImage.childImageSharp.fluid}
          />
        )}
        {title && !skipTitle && <h1 className={styles.title}>{title}</h1>}
        <div className={contentColsStyle}>
          {subTitle && style == `image_top` && (
            <div className={styles.contentColEmpty}></div>
          )}
          {style == `image_left` && (
            <div className={styles.contentCol}>
              <Img
                className={styles.rootImageLeft}
                alt={description}
                fluid={imageImage.childImageSharp.fluid}
              />
            </div>
          )}
          <div className={styles.contentCol}>
            {subTitle && (
              <div
                className={styles.subTitle}
                dangerouslySetInnerHTML={{ __html: subTitle }}
              />
            )}
            {text && (
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            )}
          </div>
          {style == `image_right` && (
            <div className={styles.contentCol}>
              <Img
                className={styles.rootImageRight}
                alt={description}
                fluid={imageImage.childImageSharp.fluid}
              />
            </div>
          )}
        </div>
      </Full>
    </Grid>
  );
};

BlockImageText.propTypes = {
  title: PropTypes.string,
  skipTitle: PropTypes.bool,
  description: PropTypes.string,
  subTitle: PropTypes.string,
  text: PropTypes.string,
  addKeyLine: PropTypes.bool,
  image: PropTypes.string,
  style: PropTypes.string,
  imageImage: PropTypes.shape({})
};

export default BlockImageText;
