import React from "react";
import PropTypes from "prop-types";
import Svg from "../Svg";
import styles from "./index.module.css";
import twitterIcon from "./twitterIcon.svg";
import linkedInIcon from "./linkedInIcon.svg";
import analytics from "../../utils/analytics";

const trackShare = (type, url) => {
  analytics(`event`, `click`, {
    event_category: `click`,
    event_label: `clickSocialShare`,
    event_value: `${type} ${url}`
  });
};

export const ShareLinkedIn = ({
  url,
  sourceUrl,
  summary,
  title,
  large,
  inverted,
  alternate,
  className
}) => {
  const queryParams = {
    url,
    sourcceUrl: sourceUrl || url,
    title: title,
    summary: summary || ``,
    source: `Atomico`
  };
  const queryString = Object.keys(queryParams)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(queryParams[k])}`)
    .join(`&`);

  return (
    <Share
      className={className}
      icon={linkedInIcon}
      url={`https://www.linkedin.com/shareArticle?${queryString}`}
      large={large}
      inverted={inverted}
      alternate={alternate}
      onClick={() => {
        trackShare(`linkedIn`, sourceUrl);
      }}
    />
  );
};

ShareLinkedIn.propTypes = {
  image: PropTypes.string,
  url: PropTypes.string,
  sourceUrl: PropTypes.string,
  summary: PropTypes.string,
  title: PropTypes.string,
  large: PropTypes.bool,
  inverted: PropTypes.bool
};

export const ShareTwitter = ({
  text,
  url,
  large,
  inverted,
  alternate,
  className
}) => {
  const queryParams = {
    text,
    url,
    hashtags: `europeantech,soet2021`
  };
  const queryString = Object.keys(queryParams)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(queryParams[k])}`)
    .join(`&`);
  return (
    <Share
      className={className}
      icon={twitterIcon}
      url={`https://twitter.com/intent/tweet?${queryString}`}
      large={large}
      inverted={inverted}
      alternate={alternate}
      onClick={() => {
        trackShare(`twitter`, url);
      }}
    />
  );
};

ShareTwitter.propTypes = {
  hashtags: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  large: PropTypes.bool,
  inverted: PropTypes.bool
};

const Share = ({ url, icon, large, inverted, alternate, className }) => {
  let style = large ? styles.rootLarge : styles.root;
  style = inverted ? styles.rootInverted : style;
  style = alternate ? styles.alternate : style;
  return (
    <a
      className={`${className !== undefined ? className : ``} ${style}`}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Svg className={styles.icon} svg={icon} />
    </a>
  );
};

Share.defaultProps = {
  type: `twitter`
};

Share.propTypes = {
  url: PropTypes.string,
  icon: PropTypes.func, // an svg
  large: PropTypes.bool,
  inverted: PropTypes.bool
};

export default Share;
