import React from "react";
import Grid from "../../../Grid";
import styles from "./index.module.css";
import SVG from "../../../Svg";

import DealroomLogo from "../../../../assets/data-partner-logos/Dealroom.svg";
import InvestEuropeLogo from "../../../../assets/data-partner-logos/InvestEurope.svg";
import SpLogo from "../../../../assets/data-partner-logos/SP.svg";
import ExtendLogo from "../../../../assets/data-partner-logos/Extend.svg";
import GrapeLogo from "../../../../assets/data-partner-logos/Grapedata.svg";
import PoliticoLogo from "../../../../assets/data-partner-logos/Politico.svg";
import PitchLogo from "../../../../assets/data-partner-logos/Pitchbook.svg";
import CraftLogo from "../../../../assets/data-partner-logos/Craft.svg";
import IndeedLogo from "../../../../assets/data-partner-logos/Indeed.svg";
import OptionLogo from "../../../../assets/data-partner-logos/OptionImpact.svg";
import LandscapeLogo from "../../../../assets/data-partner-logos/Landscape.svg";
import GoogleLogo from "../../../../assets/data-partner-logos/GoogleForStartups.svg";
import MapverseLogo from "../../../../assets/data-partner-logos/Mapverse.svg";
import SiftedLogo from "../../../../assets/data-partner-logos/Sifted.svg";
import ESGLogo from "../../../../assets/data-partner-logos/ESG_VC.svg";
import VentureLogo from "../../../../assets/data-partner-logos/Venture.svg";

const Break = () => <div className={styles.logosBreak} />;

const PartnerLogo = ({ logo, url, alt }) => (
  <a className={styles.logo} href={url} target="_blank" rel="noreferrer">
    <SVG svg={logo} alt={alt}/>
  </a>
);

const Partners = () => {
  return (
    <Grid className={styles.partnersGrid}>
      <div className={styles.heading}>
        A huge thank you to <br/>our data partners
      </div>
      <div className={styles.logos}>
        <div className={styles.logosSection}>
          <PartnerLogo
            url="https://dealroom.co/"
            logo={DealroomLogo}
            alt="Dealroom"
          />
          <PartnerLogo
            url="https://investeurope.eu/"
            logo={InvestEuropeLogo}
            alt="Invest Europe"
          />
          <PartnerLogo
            url="https://www.spglobal.com/"
            logo={SpLogo}
            alt="S&P Global"
          />
        </div>
        <Break/>
        <div className={styles.logosSection}>
          <PartnerLogo
            url="https://www.extend.vc/"
            logo={ExtendLogo}
            alt="Extend Ventures"
          />
          <PartnerLogo
            url="https://www.grape-data.com/"
            logo={GrapeLogo}
            alt="grapedata"
          />
          <PartnerLogo
            url="https://politico.com/"
            logo={PoliticoLogo}
            alt="Politico"
          />
          <PartnerLogo
            url="https://pitchbook.com/"
            logo={PitchLogo}
            alt="PitchBook"
          />
        </div>
        <Break/>
        <div className={styles.logosSection}>
          <PartnerLogo url="https://craft.co/" logo={CraftLogo} alt="Craft" />
          <PartnerLogo
            url="https://indeed.com/"
            logo={IndeedLogo}
            alt="Indeed"
          />
          <PartnerLogo
            url="https://www.optionimpact.com/"
            logo={OptionLogo}
            alt="Option Impact"
          />
          <PartnerLogo
            url="https://www.landscape.vc/"
            logo={LandscapeLogo}
            alt="Landscape"
          />
          <PartnerLogo
            url="https://startup.google.com/"
            logo={GoogleLogo}
            alt="Google for Startups"
          />
          <PartnerLogo
            url="https://mapverse.ai/"
            logo={MapverseLogo}
            alt="mapverse"
          />
          <PartnerLogo
            url="https://sifted.eu/"
            logo={SiftedLogo}
            alt="Sifted"
          />
          <PartnerLogo
            url="https://www.esgvc.co.uk/"
            logo={ESGLogo}
            alt="ESG_VC"
          />
          <PartnerLogo
            url="https://www.ventureesg.com/"
            logo={VentureLogo}
            alt="Venture ESG"
          />
        </div>
      </div>
      <div className={styles.partnerLinks}>
        <div className={styles.partnerLinkWrapper}>
          <a className={styles.partnerLink} href="mailto:research@atomico.com?subject=Becoming a State Of European Tech partner">
            Become a partner
          </a>
        </div>
        <div className={styles.partnerLinkWrapper}>
          <a className={styles.partnerLink} href="/chapter/soet-community/article/partners/">
            More on our partners
          </a>
        </div>
      </div>
    </Grid>
  );
};

export default Partners;
