import React from "react";
import NavTabContext, { NAV_TABS } from "../context";
import { graphql, useStaticQuery } from "gatsby";
import LinkArrow from "./link-arrow.svg";
import styles from "./index.module.css";

const TabButtons = () => {
  const { selectedTab, setSelectedTab } = React.useContext(NavTabContext);
  const subClass =
    {
      [NAV_TABS.PARTNERS]: styles.partnersActive
    }[selectedTab] || null;

  const {
    site: {
      siteMetadata: { insightsSite }
    }
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          insightsSite
        }
      }
    }
  `);

  const onClick = (e, newTab) => {
    e.preventDefault();
    setSelectedTab(newTab);
  };

  return (
    <ul className={`${styles.tabButtons} ${subClass || ``}`} role="navigation">
      <li className={styles.tabButton}>
        <a
          className={`${styles.tabButtonLink} ${
            selectedTab === NAV_TABS.CONTENTS ? styles.active : ``
          }`}
          href="#"
          onClick={e => onClick(e, NAV_TABS.CONTENTS)}
        >
          Contents
        </a>
      </li>
      <li className={styles.tabButton}>
        <a
          className={styles.tabButtonLink}
          href={insightsSite}
          target="_blank"
          rel="noreferrer"
        >
          Insights
          <LinkArrow className={styles.shareLink} />
        </a>
      </li>
      <li className={styles.tabButton}>
        <a
          className={`${styles.tabButtonLink} ${
            selectedTab === NAV_TABS.PREVIOUS_REPORTS ? styles.active : ``
          }`}
          href="#"
          onClick={e => onClick(e, NAV_TABS.PREVIOUS_REPORTS)}
        >
          Previous
          <br />
          Reports
        </a>
      </li>
      <li className={styles.tabButton}>
        <a
          className={`${styles.tabButtonLink} ${
            selectedTab === NAV_TABS.PARTNERS ? styles.active : ``
          }`}
          href="#"
          onClick={e => onClick(e, NAV_TABS.PARTNERS)}
        >
          Partners
        </a>
      </li>
    </ul>
  );
};

export default TabButtons;
