import React from "react"
import cn from "classnames"
import * as styles from "./index.module.css"

const ScrollPrompt = ({ ...props }) => {
  return (
    <div className={cn(styles.scrollPrompt, "gsap-scroll-prompt")}>
      <img
        className={styles.scrollPromptAsset}
        alt="Scroll to explore"
        {...props}
      />
    </div>
  )
}

export default ScrollPrompt
