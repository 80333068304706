import React from "react";
import PropTypes from "prop-types";

import PageRenderer from "../PageRenderer";
import Observer from "@researchgate/react-intersection-observer";
import get from "lodash/get";
import NavContext from "../NavContext";

const ObserveOnce = ({ handler, next }) => (
  <Observer
    onChange={(entry, unobserve) => {
      if (entry.isIntersecting) handler({ next, unobserve });
    }}
    rootMargin="500px"
  >
    <span className="observeOnce" style={{ height: 0 }} />
  </Observer>
);

ObserveOnce.propTypes = {
  handler: PropTypes.func.isRequired,
  next: PropTypes.shape({})
};

class InfiniteArticles extends React.Component {
  constructor(props) {
    super(props);
    this.onObserveNextArticle = this.onObserveNextArticle.bind(this);
    this.loadArticle = this.loadArticle.bind(this);
  }

  state = {
    articles: []
  };

  async loadArticle(articleMeta) {
    // TODO: reduce duplication by creating nextMap directly in this component
    // instead of creating it in both ArticleTemplate and ChapterTemplate
    const { nextMap } = this.props;
    const resourceLoader = get(window, `___loader.loadPage`);

    if (get(articleMeta, `pathStandalone`) && resourceLoader) {
      // console.log(`+GET resources for `, articleMeta.pathStandalone);
      const nextResources = await resourceLoader(articleMeta.pathStandalone);
      // console.log(`=GOT resources for `, articleMeta.pathStandalone);

      const nextArticle = {
        next: nextMap[articleMeta.entityId].next,
        pathStandalone: articleMeta.pathStandalone,
        resources: nextResources
      };

      // protect against cases where we end up loading the same article twice
      // const dupeIndex = this.state.articles.findIndex(
      //   article => article.pathStandalone === nextArticle.pathStandalone
      // );
      // if (dupeIndex >= 0) {
      //   console.log(`FOUND DUPE!`, this.state.articles[dupeIndex]);
      //   return;
      // }

      this.setState(state => {
        return {
          articles: [...state.articles, nextArticle]
        };
      });
    }
  }

  onObserveNextArticle({ next, unobserve }) {
    unobserve();
    requestIdleCallback(() => {
      this.loadArticle(next);
    });
  }

  render() {
    const { FooterComponent, children, article } = this.props;
    const { articles } = this.state;
    const current = articles.length > 0 ? articles.slice(-1).pop() : article;
    const next = get(current, `next`, false);
    return (
      <React.Fragment>
        {children}
        <ObserveOnce
          handler={this.onObserveNextArticle}
          next={get(article, `next`, null)}
        />
        {articles.length > 0 &&
          articles.map(a => {
            return (
              a.resources && (
                <React.Fragment key={a.pathStandalone}>
                  <PageRenderer
                    location={{ pathname: a.pathStandalone }}
                    key={a.pathStandalone}
                    pageResources={a.resources}
                  />
                  <ObserveOnce
                    handler={this.onObserveNextArticle}
                    next={get(a, `next`, null)}
                  />
                </React.Fragment>
              )
            );
          })}

        {next && next.type === `chapter` && <FooterComponent next={next} />}
      </React.Fragment>
    );
  }
}

InfiniteArticles.propTypes = {
  // actually `article` could be a chapter or an article, TODO: fix naming
  article: PropTypes.shape({
    entityId: PropTypes.number
  }),
  FooterComponent: PropTypes.func,
  nextMap: PropTypes.shape({}),
  activePathname: PropTypes.string
};

const provideContext = Component => props => {
  return (
    <NavContext.Consumer>
      {({ activePathname }) => (
        <Component activePathname={activePathname} {...props} />
      )}
    </NavContext.Consumer>
  );
};

export default provideContext(InfiniteArticles);
