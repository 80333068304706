import React from "react";
import BlockChart from "../BlockChart";
import PrimaryButton from "../PrimaryButton";

import styles from "./index.module.css";
import BlockChartImage from "../BlockChartImage";

// A chart with a link back to the report. Used for social media sharing and
// to generate chart images
const StandaloneChart = ({ chart, chartId, groupId, articlePath }) => {
  const chartComponent =
    groupId === `image` ? (
      <BlockChartImage showWatermark={true} {...chart} />
    ) : (
      <BlockChart
        {...chart}
        chartId={chartId}
        initialGroupId={groupId}
        selectableGroups={false}
        showWatermark={true}
        width={`print`}
        animate={false}
      />
    );
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.titleWrap}>
          <h1 className={styles.title}>Chart</h1>
          <h2 className={styles.titleNumber}>{chart.titleNumber}</h2>
        </div>
        <PrimaryButton
          to={`${articlePath}?cgid=${groupId}#chart-${chartId}`}
          dark={true}
        >
          View in article
        </PrimaryButton>
      </div>
      <div
        className={`${styles.chartWrap} page-chart`}
        id={`chart-${chartId}-${groupId}`}
      >
        {chartComponent}
      </div>
    </div>
  );
};

export default StandaloneChart;
