import React from "react";

const TopBarContext = React.createContext({
  topBarBottom: 0,
  setTopBarBottom: () => {}
});

export const TopBarContextProvider = ({ children }) => {
  const [topBarBottom, setTopBarBottom] = React.useState(0);

  return (
    <TopBarContext.Provider value={{ topBarBottom, setTopBarBottom }}>
      {children}
    </TopBarContext.Provider>
  );
};

export default TopBarContext;
