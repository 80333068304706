import React from "react";

const ScrollContext = React.createContext({ scrollX: null, scrollY: null });

const ScrollContextProvider = ({ children }) => {
  const [scroll, setScroll] = React.useState({ scrollX: null, scrollY: null });

  React.useEffect(() => {
    const updateScroll = () =>
      setScroll({ scrollX: window.scrollX, scrollY: window.scrollY });

    document.addEventListener(`scroll`, updateScroll);
    return () => document.removeEventListener(`scroll`, updateScroll);
  }, [setScroll]);

  return (
    <ScrollContext.Provider value={scroll}>{children}</ScrollContext.Provider>
  );
};

export { ScrollContextProvider };
export default ScrollContext;
