import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import * as styles from "./index.module.css"

const LogosSupporters = ({ text, logo1, logo2, logo3 }) => {
  return (
    <div className={cn(styles.logosSupporters, "gsap-logo-supporters")}>
      {text && <span className={styles.logosSupportersText}>{text}</span>}

      <div className={styles.logosSupportersAssets}>
        {logo1 && <div className={styles.logosSupportersItem}>{logo1}</div>}
        {logo2 && <div className={styles.logosSupportersItem}>{logo2}</div>}
        {logo3 && <div className={styles.logosSupportersItem}>{logo3}</div>}
      </div>
    </div>
  )
}

LogosSupporters.propTypes = {
  text: PropTypes.string,
  logo1: PropTypes.node,
  logo2: PropTypes.node,
  logo3: PropTypes.node,
}

LogosSupporters.defaultProps = {
  text: "",
  logo1: null,
  logo2: null,
  logo3: null,
}

export default LogosSupporters
