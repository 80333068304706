import React, { useRef, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import cn from "classnames"
import * as styles from "./index.module.css"

gsap.registerPlugin(ScrollTrigger)

const SidewaysImage = ({ ...props }) => {
  const image = useRef(null)
  const asset = useRef(null)

  useEffect(() => {
    const img = image.current
    const ass = asset.current
    const inner = img.querySelector(".gsap-sideways-image-inner")

    gsap.fromTo(
      inner,
      { x: "0" },
      {
        x: "-50%",
        ease: "none",
        scrollTrigger: {
          // markers: true,
          id: "sideways",
          trigger: img,
          start: "top bottom",
          end: "bottom top",
          scrub: 0.5,
          ease: "none",
        },
      }
    )

    ass.addEventListener("load", () => ScrollTrigger.refresh(), {
      once: true,
    })
  }, [])

  return (
    <div ref={image} className={cn(styles.sidewaysImage)}>
      <div
        className={cn(styles.sidewaysImageInner, "gsap-sideways-image-inner")}
      >
        <img
          ref={asset}
          className={cn(styles.sidewaysImageAsset)}
          alt=""
          {...props}
        />
        <img className={cn(styles.sidewaysImageAsset)} alt="" {...props} />
      </div>
    </div>
  )
}

export default SidewaysImage
