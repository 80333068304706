import React from "react"
import PropTypes from "prop-types"
import * as styles from "./index.module.css"

const HighlightTitle = ({ children, hidden }) => {
  return <p className={styles.highlightTitle}>{children}</p>
}

HighlightTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

HighlightTitle.defaultProps = {
  children: null,
}

export default HighlightTitle
