import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import SearchToggleButton from "../SearchToggleButton";
import ScrollContext from "../ScrollProvider";
import NavContext from "../NavContext";
import TopBarContext from "./context";
import ReportLogoDark from "../../assets/report-logo-dark.svg";
import ReportLogoLight from "../../assets/report-logo-light.svg";
import AtomicoLogoLight from "../../assets/data-partner-logos/atomico-light.svg";
import AtomicoLogoDark from "../../assets/data-partner-logos/atomico-dark.svg";
import SmallAtomicoLogoLight from "../../assets/data-partner-logos/atomico-small-light.svg";
import SmallAtomicoLogoDark from "../../assets/data-partner-logos/atomico-small-dark.svg";
import OrrickLogoLight from "../../assets/data-partner-logos/orrick-light.svg";
import OrrickLogoDark from "../../assets/data-partner-logos/orrick-dark.svg";
import SlushLogoLight from "../../assets/data-partner-logos/slush-light.svg";
import SlushLogoDark from "../../assets/data-partner-logos/slush-dark.svg";
import SvbLogoLight from "../../assets/data-partner-logos/svb-light.svg";
import SvbLogoDark from "../../assets/data-partner-logos/svb-dark.svg";
import BgLogoLight from "../../assets/data-partner-logos/bg-light.svg";
import BgLogoDark from "../../assets/data-partner-logos/bg-dark.svg";
import SearchInput from "../SearchInput";
import SearchPanel from "../SearchInput/SearchPanel";
import MenuBurger from "../MenuBurger";
import {Link} from "gatsby";
import DownloadButton from "../DownloadButton";

const TopBar = () => {
  const { reportNavOpen, searchBarOpen, toggleSearchBar, toggleReportNav, currentSearch } = React.useContext(
    NavContext
  );
  const { setTopBarBottom } = React.useContext(TopBarContext);
  const [top, setTop] = React.useState(0);
  const [lastScroll, setLastScroll] = React.useState(null);
  const [scrollChange, setScrollChange] = React.useState(0);
  const { scrollY } = React.useContext(ScrollContext);
  const ref = React.useRef();

  React.useEffect(() => {
    if (scrollY !== null) {
      setScrollChange(scrollY - lastScroll);
      setLastScroll(scrollY);
    }
  }, [setScrollChange, lastScroll, setLastScroll, scrollY]);

  React.useEffect(() => {
    if (ref && ref.current && scrollChange !== 0) {
      setTop(
        scrollChange < 0 || scrollY <= ref.current.offsetHeight
          ? 0
          : -ref.current.offsetHeight
      );
    }
  }, [ref, top, setTop, scrollChange, scrollY]);

  // set the bottom position of the nav so that other components can use it
  React.useEffect(() => {
    if (ref && ref.current) {
      setTopBarBottom(top + ref.current.offsetHeight);
    }
  }, [top, ref, setTopBarBottom]);

  React.useEffect(() => {
    if (
      ref &&
      ref.current &&
      scrollChange !== 0 &&
      searchBarOpen &&
      !currentSearch
    ) {
      toggleSearchBar();
    }
  }, [ref, top, toggleSearchBar, currentSearch, scrollChange]);

  // setup the styles for the different versions of the logos
  const lightIconStyles = React.useMemo(
    () => (reportNavOpen ? { opacity: 1 } : { opacity: 0 }),
    [reportNavOpen]
  );
  const darkIconStyles = React.useMemo(
    () => (reportNavOpen ? { opacity: 0 } : { opacity: 1 }),
    [reportNavOpen]
  );

  return (
    <div
      className={`${styles.root} ${reportNavOpen ? styles.navIsOpen : ``}`}
      style={{ top }}
      ref={ref}
    >
      <MenuBurger/>
      <SearchToggleButton
        className={`${styles.searchToggleButtonSmall} ${
          searchBarOpen ? styles.searchToggleButtonActive : ``
        }`}
      />
      <div className={styles.inner}>
        <a
          href="https://www.atomico.com"
          className={`${styles.logoLink} ${styles.smallAtomicoLogoWrapper}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <SmallAtomicoLogoDark
            className={styles.colorChangingIcon}
            style={darkIconStyles}
            alt="Atomico"
          />
          <SmallAtomicoLogoLight
            className={styles.colorChangingIcon}
            style={lightIconStyles}
            alt="Atomico"
          />
        </a>
        <div className={styles.reportLogoWrapper}>
          <div className={styles.reportLogo}>
            <Link to="/chapter/word-our-sponsors" >
              <ReportLogoDark
                className={styles.colorChangingIcon}
                style={darkIconStyles}
                alt="SOET"
              />
              <ReportLogoLight
                className={styles.colorChangingIcon}
                style={lightIconStyles}
                alt="SOET"
              />
            </Link>
          </div>
        </div>
        <div className={styles.partners}>
          <a
            href="https://atomico.com/"
            className={`${styles.logoLink} ${styles.atomicoLogoWrapper}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <AtomicoLogoDark
              className={styles.colorChangingIcon}
              style={darkIconStyles}
              alt="Atomico"
            />
            <AtomicoLogoLight
              className={styles.colorChangingIcon}
              style={lightIconStyles}
              alt="Atomico"
            />
          </a>
          <div className={styles.separator} />
          <a
            href="https://www.slush.org"
            className={`${styles.logoLink} ${styles.slushLogoWrapper}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SlushLogoDark
              className={styles.colorChangingIcon}
              style={darkIconStyles}
              alt="Slush"
            />
            <SlushLogoLight
              className={styles.colorChangingIcon}
              style={lightIconStyles}
              alt="Slush"
            />
          </a>
          <div className={styles.separator} />
          <a
            href="https://www.orrick.com"
            className={`${styles.logoLink} ${styles.orrickLogoWrapper}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <OrrickLogoDark
              className={styles.colorChangingIcon}
              style={darkIconStyles}
              alt="Orrick"
            />
            <OrrickLogoLight
              className={styles.colorChangingIcon}
              style={lightIconStyles}
              alt="Orrick"
            />
          </a>
          <div className={styles.separator} />
          <a
            href="https://www.svb.com/uk"
            className={`${styles.logoLink} ${styles.svbLogoWrapper}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SvbLogoDark
              className={styles.colorChangingIcon}
              style={darkIconStyles}
              alt="SVB"
            />
            <SvbLogoLight
              className={styles.colorChangingIcon}
              style={lightIconStyles}
              alt="SVB"
            />
          </a>
          <div className={styles.separator} />
          <a
            href="https://www.bailliegifford.com/"
            className={`${styles.logoLink} ${styles.bgLogoWrapper}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <BgLogoDark
              className={styles.colorChangingIcon}
              style={darkIconStyles}
              alt="Baillie Gifford"
            />
            <BgLogoLight
              className={styles.colorChangingIcon}
              style={lightIconStyles}
              alt="Baillie Gifford"
            />
          </a>
        </div>
        <DownloadButton className={styles.topbarDownloadButton} />
        <div className={`${styles.separator} ${styles.fullBleed}`} />
      </div>
      <SearchToggleButton
        className={`${styles.searchToggleButton} ${
          searchBarOpen ? styles.searchToggleButtonActive : ``
        }`}
      />
      {searchBarOpen && <SearchInput />}
      <SearchPanel />
    </div>
  );
};

TopBar.propTypes = {
  reportPath: PropTypes.string,
  toggleOnboarding: PropTypes.func,
  onDownloadClick: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default TopBar;
