import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import * as styles from "./index.module.css"

gsap.registerPlugin(ScrollTrigger)

const AvatarWall = ({ children }) => {
  const list = useRef(null)

  useEffect(() => {
    const element = list.current
    const items = element.querySelectorAll(`li`)

    gsap.set(items, { scale: 0, opacity: 0 })

    ScrollTrigger.create({
      // markers: true,
      id: "list",
      trigger: element,
      start: "top 75%",
      once: true,
      onEnter: () => {
        gsap.fromTo(
          items,
          { scale: 0, opacity: 0 },
          {
            scale: 1,
            opacity: 1,
            stagger: {
              each: 0.1,
              from: "start",
              grid: "auto",
            },
            ease: "back.out(2)",
          }
        )
      },
    })
  }, [])

  return (
    <>
      {children && children.length && (
        <ul ref={list} className={styles.avatarWall}>
          {children.map((child, index) => (
            <li key={index} className={styles.avatarWallItem}>
              {child}
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

AvatarWall.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

AvatarWall.defaultProps = {
  children: null,
}

export default AvatarWall
