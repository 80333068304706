import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";

import store from "../store";
import { ScrollContextProvider } from "../components/ScrollProvider";
import { TopBarContextProvider } from "../components/TopBar/context";
import Helmet from "react-helmet";

// implement Gatsby's 'wrapRootElement' API for use both in gatsby-browser
// and gatsby-node. See https://github.com/gatsbyjs/gatsby/tree/master/examples/using-redux

const wrapWithProvider = ({ element }) => (
  <>
    <Helmet>
      <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
    </Helmet>
    <Provider store={store}>
      <ScrollContextProvider>
        <TopBarContextProvider>{element}</TopBarContextProvider>
      </ScrollContextProvider>
    </Provider>
  </>
);

wrapWithProvider.propTypes = {
  element: PropTypes.element
};

export default wrapWithProvider;
