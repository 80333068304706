import storage from "../../utils/storage";

// Actions
const typePrefix = `surveyNoticeComplete/`;
const CLEAR = `${typePrefix}CLEAR`;
const SET = `${typePrefix}SET`;

// Selectors

export const getSurveyNoticeComplete = state => state.surveyNoticeComplete.surveyNoticeComplete;

// Action creators

/**
 *
 * @return {function} thunk action creator
 */
export const setSurveyNoticeComplete = () => dispatch => {
  dispatch({ type: SET });
  const serialised = JSON.stringify({ surveyNoticeComplete: true });
  storage.setItem(typePrefix, serialised);
};

export const clearSurveyNoticeComplete = () => dispatch => {
  dispatch({ type: CLEAR });
  const serialised = JSON.stringify(emptyState);
  storage.setItem(typePrefix, serialised);
};

const emptyState = {
  surveyNoticeComplete: false
};

/**
 * Rehydrate the state from storage.
 * @return {object} Previously saved auth state or an empty object
 */
export const rehydrate = () => {
  const surveyNoticeComplete = storage.getItem(typePrefix);
  const hydrated =
    typeof surveyNoticeComplete === `string` ? JSON.parse(surveyNoticeComplete) : emptyState;
  return hydrated;
};

// Reducer
export function surveyNoticeCompleteReducer(state = rehydrate(), action = {}) {
  switch (action.type) {
    case SET:
      return {
        surveyNoticeComplete: true
      };
    case CLEAR:
      return { ...emptyState };
    default:
      return state;
  }
}
