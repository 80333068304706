/**
 * This file responsible for the download report flow. The first time a user
 * requests to download a chart, they will be asked for their email address
 * and to agree to the terms and conditions.
 *
 * The flow is roughly like this:
 *
 * - Click 'download report button'
 * - Do we have state.user?
 *    - No, trigger request user details modal flow
 *    - Yes, go to next step
 * - Trigger file download
 */
import { getUser } from "../user";
// import { getChart, setChart } from "../chart"; // eslint-disable-line no-unused-vars
import { showModal, modals, mailchimpRequest } from "../../state/modal";
import analytics from "../../utils/analytics";

/**
 * Request report download - check for user details. If available then skip
 * straight to triggering the chart download. If _not_ available, show the
 * standard user request details.
 **/
// eslint-disable-next-line no-unused-vars
export const requestReportDownload = () => (dispatch, getState) => {
  const state = getState();
  const user = getUser(state);

  // get user details
  const hasUser =
    typeof user.email === `string` &&
    user.email.length > 1 &&
    user.termsAgreed === true;

  // Default modal to show next.
  const modal = { modalType: modals.DOWNLOAD_REPORT };

  if (hasUser) {
    // if user details, goto 'agreed' modal
    dispatch(prepReportDownload());
  } else {
    dispatch(showModal(modal));
  }
};

// eslint-disable-next-line no-unused-vars
export const prepReportDownload = () => (dispatch, getState) => {
  const user = getUser(getState());
  mailchimpRequest(user.email, user.newsletter).then(() => {
    window.location.href = `https://soet-pdf.s3.eu-west-2.amazonaws.com/State_of_European_Tech_2021.pdf`;
    dispatch(
      showModal({
        modalType: modals.DOWNLOAD_SUCCESS,
        modalProps: {
          text: `Your download will start in a few moments. Check your browser's download folder.`
        }
      })
    );
    analytics(`event`, `download`, {
      event_category: `download`,
      event_label: `downloadFullReport`
    });
  });
};
