import React from "react";
import qs from "querystring";

const AutoReportDownloadModal = ({ requestDownload }) => {
  if (
    typeof location !== `undefined` &&
    `downloadReport` in qs.parse(location.search.substr(1))
  ) {
    requestDownload();
  }

  return <></>;
};

export default AutoReportDownloadModal;
