import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import NavContext from "../../../NavContext";
import styles from "./index.module.css";
import Illustration from "../../../Illustration";
import ToggleArrowIcon from "./toggle-arrow.svg";
import DownloadButton from "../../../DownloadButton";

const ArticleEntry = ({ article, path, title, numbering }) => {
  const { activePathname, closeReportNav } = React.useContext(NavContext);
  const actualPath = path || article.path;

  return (
    <li className={styles.articleEntry}>
      <Link
        className={`${styles.articleEntryLink} ${
          actualPath === activePathname ? styles.active : ``
        }`}
        to={actualPath}
        onClick={() => closeReportNav()}
      >
        <span className={styles.numbering}>
          {numbering || article.numbering}
        </span>
        <span className={styles.dash}>&#8212;</span>
        <span className={styles.title}>{title || article.title}</span>
      </Link>
    </li>
  );
};

const ChapterEntry = ({ chapter, scrollToView }) => {
  const { activePathname, closeReportNav } = React.useContext(NavContext);
  const [isActive, setIsActive] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [innerHeight, setInnerHeight] = React.useState(0);
  const innerRef = React.useRef();
  const outerRef = React.useRef();

  const executiveSummary = chapter.titleNumber === `00`;

  React.useEffect(() => {
    const isCurrent =
      (activePathname || ``).startsWith(chapter.path) ||
      (activePathname === `/` && executiveSummary) ||
      (activePathname === `/1/` && executiveSummary);
    setIsActive(isCurrent);
    setIsOpen(isCurrent && !executiveSummary);
  }, [activePathname, setIsActive, setIsOpen, chapter, executiveSummary]);

  React.useEffect(() => {
    if (innerRef && innerRef.current && isOpen) {
      setInnerHeight(innerRef.current.offsetHeight);
    } else {
      setInnerHeight(0);
    }
  }, [isOpen, innerRef, setInnerHeight]);

  React.useEffect(() => {
    if (outerRef && outerRef.current && isOpen && scrollToView) {
      setTimeout(
        () =>
          outerRef.current.scrollIntoView({
            behavior: `smooth`,
            block: `center`
          }),
        500
      );
    }
  }, [isOpen, outerRef, scrollToView]);

  return (
    <li
      ref={outerRef}
      className={`${styles.chapterEntry} ${(isActive) ? styles.active : ``} ${
        isOpen ? styles.open : ``
      }`}
    >
      <div className={styles.chapterEntryGrid}>
        <span className={styles.chapterEntryTitleNumber}>
          {chapter.titleNumber}
        </span>
        <Link
          className={styles.chapterEntryLink}
          to={executiveSummary ? chapter.path : `#`}
          onClick={e => {
            if (executiveSummary) {
              closeReportNav();
            } else {
              e.preventDefault();
              setIsOpen(!isOpen);
            }
          }}
        >
          <span className={styles.chapterEntryTitle}>{chapter.title}</span>
        </Link>
        <Illustration
          title={chapter.title}
          className={styles.chapterEntryIllustration}
          illustrationImage={chapter.illustrationImage}
        />

        {executiveSummary ? (
          <Link
            className={styles.chapterEntryExpander}
            to={chapter.path}
            onClick={() => closeReportNav()}
          >
            <ToggleArrowIcon
              className={styles.chapterEntryExpanderIconDirect}
            />
          </Link>
        ) : (
          <>
            <button
              className={styles.chapterEntryExpander}
              onClick={e => {
                e.preventDefault();
                setIsOpen(!isOpen);
              }}
            >
              <ToggleArrowIcon className={styles.chapterEntryExpanderIcon} />
            </button>
            <div
              className={styles.articleEntries}
              style={{ height: `${innerHeight}px` }}
            >
              <ul ref={innerRef}>
                <ArticleEntry
                  path={chapter.path}
                  numbering={`${parseInt(chapter.titleNumber)}.0`}
                  title="Introduction"
                />
                {chapter.soetArticle.map(a => (
                  <ArticleEntry article={a} key={a.id} />
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </li>
  );
};

const MainContents = ({scrollToView}) => {
  const {
    allSoetChapter: { nodes: chapters }
  } = useStaticQuery(graphql`
    {
      allSoetChapter {
        nodes {
          id
          title
          path
          titleNumber
          illustrationImage {
            childImageSharp {
              fluid(quality: 80, maxWidth: 340) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            extension
            publicURL
          }
          soetArticle {
            title
            numbering
            id
            path
          }
        }
      }
    }
  `);

  return (
    <>
      <ul>
        {chapters.map(c => (
          <ChapterEntry chapter={c} key={c.id} scrollToView={scrollToView === undefined ? true : scrollToView} />
        ))}
      </ul>
      <div className={styles.navDownloadContainer}>
        <DownloadButton className={styles.navDownloadButton} />
      </div>
    </>
  );
};

export default MainContents;
