import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import StandaloneChart from "../components/StandaloneChart";
import Seo from "../components/Seo";
import { graphql } from "gatsby";

const StandaloneChartTemplate = ({ data, pageContext }) => {
  const { chartId, groupId, articlePath, chartPath } = pageContext;
  const isImage = groupId === `image`;
  const chart = data.soetArticle.blocks.find(
    block =>
      get(block, `chartId`) === chartId &&
      get(block, `entityType`) === (isImage ? `ChartImageBlock` : `ChartBlock`)
  );
  const seoImage = isImage
    ? chart.svg
    : `/chart/chart-${chartId}-${groupId}.png`;

  // Don't show the insight block on these standalone pages
  // if (chart.insightBlock) delete chart.insightBlock;

  return (
    <React.Fragment>
      <Seo
        title={chart.title}
        description={chart.insight || null}
        image={seoImage}
        path={chartPath}
      />
      <StandaloneChart
        chart={chart}
        articlePath={articlePath}
        chartId={chartId}
        groupId={groupId}
      />
    </React.Fragment>
  );
};

StandaloneChartTemplate.propTypes = {
  data: PropTypes.shape({
    soetArticle: PropTypes.shape({
      block: PropTypes.shape()
    })
  }),
  pageContext: PropTypes.shape({
    articlePath: PropTypes.string,
    chartId: PropTypes.number
  })
};

export default StandaloneChartTemplate;

/**
 * Get chart blocks for this article
 */
// eslint-disable-next-line no-undef
export const articleQuery = graphql`
  query ChartArticleQuery($entityId: Int!) {
    soetArticle(entityId: { eq: $entityId }) {
      entityId
      blocks {
        ...ChartBlockFragment
        ...ChartImageBlockFragment
      }
    }
  }
`;
