import React from "react";
import Icon from "./search-icon.svg";
import IconOpen from "./search-icon-open.svg";
import IconWhite from "./search-icon-white.svg";
import styles from "./index.module.css";
import NavContext from "../NavContext";

const SearchToggleButton = ({ className }) => {
  const { toggleSearchBar, searchBarOpen, reportNavOpen } = React.useContext(NavContext);

  let UseIcon = Icon;
  if (searchBarOpen) {
    UseIcon = IconOpen;
  }
  else if(reportNavOpen){
    UseIcon = IconWhite;
  }

  return (
    <button
      role="button"
      onClick={e => {
        e.preventDefault();
        toggleSearchBar();
      }}
      className={className || styles.searchToggleButton}
    >
      <UseIcon classNzme={styles.icon} />
    </button>
  );
};

export default SearchToggleButton;
