import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import * as styles from "./index.module.css"

const VideoSection = ({ headline, text, children }) => {
  return (
    <div className={cn(styles.videoSection)}>
      <div className={cn(styles.videoSectionInner)}>
        {headline && headline.length && (
          <p className={cn(styles.videoSectionHeadline)}>{headline}</p>
        )}

        {children && (
          <div className={cn(styles.videoSectionContent)}>{children}</div>
        )}

        {text && text.length && (
          <p className={cn(styles.videoSectionText)}>{text}</p>
        )}
      </div>
    </div>
  )
}

VideoSection.propTypes = {
  headline: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

VideoSection.defaultProps = {
  headline: "",
  text: "",
  children: null,
}

export default VideoSection
