import React from "react";
import "./index.scss";
import { connectRefinementList } from "react-instantsearch-dom";

class RefinementList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: {},
      clickedId: {}
    };
    this.determineItemStyle = this.determineItemStyle.bind(this);
  }

  determineItemStyle(i) {
    if (
      Object.keys(this.state.clickedId).length === 0 ||
      this.state.clickedId === `clear`
    ) {
      return i + `active`; /* no item selected, all active*/
    }

    const isItemSelected = this.state.clickedId === i;
    return isItemSelected ? i + `active` : i + `notactive`;
  }

  render() {
    const { items, refine } = this.props;

    return (
      <div className={`aisRefinementList`}>
        {items.map((item, i) => {
          return (
            <div className={item.label} key={item.label}>
              <div
                className={
                  `searchFacet` + ` ` + this.determineItemStyle(item.label)
                }
                id={item.label}
                key={i}
                onClick={event => {
                  event.preventDefault;
                  this.setState(prevState => {
                    let clickedId = item.label;
                    let refineWith = item.label;
                    if (prevState.clickedId === clickedId) {
                      clickedId = `clear`;
                      refineWith = ``;
                    }
                    refine(refineWith);
                    return {
                      clickedId: clickedId
                    };
                  });
                }}
              >
                {item.label}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

const CustomRefinementList = connectRefinementList(RefinementList);

export default CustomRefinementList;
