import { getUser } from "../user";
import { getImage, setImage } from "../image";
import { showModal, modals, mailchimpRequest } from "../../state/modal";
import analytics from "../../utils/analytics";

export const requestImageDownload = ({ url, filename }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const user = getUser(state);
  dispatch(setImage({ url, filename }));

  // get user details
  const hasUser =
    typeof user.email === `string` &&
    user.email.length > 1 &&
    user.termsAgreed === true;

  // Default modal to show next.
  const modal = { modalType: modals.DOWNLOAD_IMAGE };

  if (hasUser) {
    // There's only one choice for download so go straight to image download.
    dispatch(prepShareImageDownload());
    return false;
  }

  dispatch(showModal(modal));
};

export const prepShareImageDownload = () => (dispatch, getState) => {
  const state = getState();
  const user = getUser(state);

  mailchimpRequest(user.email, user.newsletter).then(() => {
    dispatch(
      showModal({
        modalType: modals.DOWNLOAD_SUCCESS,
        modalProps: {
          text: `Your download will start in a few moments. Check your browser's download folder.`
        }
      })
    );
    dispatch(downloadShareImage());
  });
};

export const downloadShareImage = () => (dispatch, getState) => {
  const state = getState();
  const image = getImage(state);
  analytics(`event`, `download`, {
    event_category: `download`,
    event_label: `downloadImage`,
    event_value: image.url
  });
  // Create a temporary element and click it to download the image.
  // These images do not have the "Content-Disposition: attachment" header.
  // So we do this to try and force the image to download, instead of display
  // in the browser
  const element = document.createElement(`a`);
  element.setAttribute(`href`, image.url);
  element.setAttribute(`download`, image.filename);
  element.style.display = `none`;

  // if download attribute not supported, just open in a new browser window
  if (!element.hasAttribute(`download`)) element.target = `_blank`;

  // Attach, click, remove:
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
