import React from "react";

import PropTypes from "prop-types";
import styles from "./index.module.css";

import { ShareLinkedIn, ShareTwitter } from "../Share";
import { graphql, StaticQuery } from "gatsby";

class ReportLayout extends React.Component {
  /* ReportNav lives higher up the component tree, here we just have styles
     on .contentWrapper that leaves space for it
  */
  constructor(props) {
    super(props);
    this.state = { showMore: false };

    this.closeTopBarMore = this.closeTopBarMore.bind(this);
  }

  closeTopBarMore() {
    this.setState({ showMore: false });
  }

  componentDidMount() {
    this.setState({ showMore: false });
  }
  render() {
    const { title, siteUrl, path } = this.props;
    const shareUrl = `${siteUrl}${path}`;

    return (
      <div className={styles.contentWrapper} id="report-content">
        {this.props.children}
        <div hidden className={styles.fixedSocial}>
          <div>
            <ShareLinkedIn
              className={styles.shareIcon}
              url={`${siteUrl}`}
              sourceUrl={shareUrl}
              title={title}
              large={true}
            />
          </div>
          <div>
            <ShareTwitter text={title} url={shareUrl} large={true} />
          </div>
        </div>
      </div>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            siteUrl: url
          }
        }
      }
    `}
    render={data => (
      <ReportLayout siteUrl={data.site.siteMetadata.siteUrl} {...props} />
    )}
  />
);

ReportLayout.propTypes = {
  titleNumber: PropTypes.string,
  title: PropTypes.string,
  entityId: PropTypes.number,
  siteUrl: PropTypes.string,
  path: PropTypes.string
};
