import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import Insight from "../Insight";
import Grid, { Half, Thin } from "../Grid";

const BlockInsight = ({ id, title, skipTitle, items, path }) => {
  const showTitle = items.length < 3;
  const headlineStyle =
    title && items.length === 1 ? styles.headlineLong : styles.insight;

  let InsightTitleGrid = Thin;
  const InsightGrid = Thin;
  let insightCentre = false;
  if (items.length === 1) {
    if (title) {
      // Showing title and 1 insight.
      InsightTitleGrid = Half;
    } else {
      // Showing only insight so centre.
      insightCentre = true;
    }
  }

  return (
    <Grid className={styles.root}>
      {showTitle && (
        <InsightTitleGrid className={`${headlineStyle} ${styles.title}`}>
          {title}
        </InsightTitleGrid>
      )}
      {items.length > 0 && (
        <>
          {items.slice(0, 3).map((item, i) => (
            <InsightGrid key={i}>
              <Insight
                id={id}
                key={i}
                path={path}
                i={i}
                centre={insightCentre}
                parentTitle={title}
                {...item}
              />
            </InsightGrid>
          ))}
        </>
      )}
    </Grid>
  );
};

BlockInsight.defaultProps = {
  items: []
};

BlockInsight.propTypes = {
  title: PropTypes.string,
  skipTitle: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape(Insight.propTypes))
};

export default BlockInsight;
