// Actions
const typePrefix = `image/`;
const SET = `${typePrefix}SET`;
const CLEAR = `${typePrefix}CLEAR`;

// Selectors
export const getImage = state => state.image;

export const setImage = ({ url, filename }) => ({
  type: SET,
  url,
  filename
});

export const clearImage = () => ({
  type: CLEAR
});

const emptyState = {
  url: null,
  filename: null
};

// Reducer
export function imageReducer(state = emptyState, action = {}) {
  switch (action.type) {
    case SET:
      return {
        url: action.url,
        filename: action.filename
      };
    case CLEAR:
      return { ...emptyState };
    default:
      return state;
  }
}
